export const getters = {
    list: state => state.list,
    inventory: state => state.inventory,
    selectList: state => state.selectList,
    filialProducts: state => state.filialProducts,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort, 
    images_list: state => state.images_list, 
    replace_details: state => state.replace_details,
    calculation_types: state => state.calculation_types,
    priceList: state => state.priceList,
    minimumWeightList: state => state.minimumWeightList,

  };
  