<template >
    <div class="app-modal__body p-5 pb-0">
        <div v-loading="loadingData" class="timeline-items__right rounded-sm w-100 p-4">
            <el-steps direction="vertical" :space="50" :active="100" process-status="process" finish-status="process" status="process">
                <el-step :icon="getIcon(history.type)" :title="history.type_name + ' -- ' + (history.user ? history.user.name : '')" v-for="(history, index) in historyData" :key="'hist_' + index">
                    <div slot="description">
                        <span class="step-description">
                            {{ history.code }} - {{ history.date }}
                        </span>

                        <div v-for="(info_value, info_key) in history.info" :key="'row_'+info_key" class="mb-3">
                            <!-- PACKAGES -->
                            <el-row v-if="Array.isArray(info_value) && info_key == 'packages' && info_value.length > 0">
                                <span class="font-bold"> {{ $t('message.' + info_key) }}: </span>
                                <dealHistoryForPackages :dealHistoryPackages="info_value"></dealHistoryForPackages>
                            </el-row>
                            <!-- PRODUCTS -->
                            <el-row v-else-if="Array.isArray(info_value) && info_key == 'products' && info_value.length > 0">
                                <span class="font-bold"> {{ $t('message.' + info_key) }}: </span>
                                <dealHistoryForProducts :setBorder="true" :dealHistoryProducts="info_value"></dealHistoryForProducts>
                            </el-row>
                            <!-- SERVICES -->
                            <el-row v-else-if="Array.isArray(info_value) && info_key == 'services' && info_value.length > 0">
                                <span class="font-bold"> {{ $t('message.' + info_key) }}: </span>
                                <dealHistoryForServices :dealHistoryServices="info_value"></dealHistoryForServices>
                            </el-row>
                            <!-- SERVICES -->
                            <el-row v-else-if="Array.isArray(info_value) && info_key == 'payments' && info_value.length > 0">
                                <span class="font-bold"> {{ $t('message.' + info_key) }}: </span>
                                <dealHistoryForPayments :dealHistoryPayments="info_value"></dealHistoryForPayments>
                            </el-row>
                            <!-- DEAL INFO -->
                            <el-row v-else-if="(Array.isArray(info_value) || typeof info_value === 'object') && !['packages', 'products', 'services', 'payments'].includes(info_key)">
                                <h4 v-if="info_key != 'new_values'">{{ $t('message.' + info_key) }}</h4>
                                <el-col v-if="value" :span="8" v-for="(value, key) in info_value" :key="'col_'+key">
                                    <span class="font-bold"> {{ $t('message.' + key) }}: </span> {{ value }}
                                </el-col>
                            </el-row>

                            <el-col v-else-if="!['packages', 'products', 'services', 'payments'].includes(info_key)">
                                <span class="font-bold"> {{ $t('message.' + info_key) }}: </span> {{ info_value }}
                            </el-col>
                        </div>
                    </div>
                    
                </el-step>
            </el-steps>
        </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  import dealHistoryForPackages from "./dealHistoryForPackages";
  import dealHistoryForProducts from "./dealHistoryForProducts";
  import dealHistoryForServices from "./dealHistoryForServices";
  import dealHistoryForPayments from "./dealHistoryForPayments";

  export default {
    props: {
        deal_id: {
            default: null
        },
        activeName: {
            default: ''
        },
        historyList: {
            default: Array
        },
    },
    components: {
        dealHistoryForPackages,
        dealHistoryForProducts,
        dealHistoryForServices,
        dealHistoryForPayments
    },
    data() {
      return {
        tabName: 'history',
        loadingData: false,
      };
    },
    watch: {
        activeName: {
          handler: async function(newVal, oldVal) {
            if(newVal && newVal == this.tabName){
                this.fetchData();
            }
          },
          deep: true,
          immediate: true
      },
    },
    mounted() {
        if (this.types && this.types.length === 0) this.updateTypes();
    },
    computed: {
        ...mapGetters({
            list: "dealHistory/list",
            rules: "dealHistory/rules",
            model: "dealHistory/model",
            columns: "dealHistory/columns",
            types: "dealHistory/types",
        }),
        historyData() {
            if(this.historyList && !this.tabName){
                return this.historyList;
            }
            return this.list;
        }
    },
    methods: {
        ...mapActions({
            updateTypes: "dealHistory/getHistoryTypes",
            updateList: "dealHistory/index",
            save: "dealHistory/store",
        }),

        getIcon(type){
            let history_type = this.types.find(el => el.id == type);
            if(history_type && history_type.icon){
                return history_type.icon;
            }
            return  'el-icon-question';
        },

        fetchData(){
            this.loadingData = true;
            this.updateList({deal_id: this.deal_id})
                .then(res => {
                    this.loadingData = false;
                })
                .catch(err => {
                    this.loadingData = false;
                });
        }
    },
  };
  
</script>
<style>
    .mm_bordered_section {
        border: 1px solid gray;
        padding: 5px;
        border-radius: 5px;
    }
</style> 