import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    url : {
        show: true,
        title: i18n.t('message.url'),
        sortable: true,
        column: 'url'
    },
    register_rule_id : {
        show: true,
        title: i18n.t('message.registerRule'),
        sortable: true,
        column: 'register_rule_id'
    },
    show_referral : {
        show: true,
        title: i18n.t('message.show_referral'),
        sortable: true,
        column: 'show_referral'
    },
    countries : {
        show: true,
        title: i18n.t('message.countries'),
        sortable: true,
        column: 'countries'
    },
    filials : {
        show: true,
        title: i18n.t('message.filials'),
        sortable: true,
        column: 'filials'
    },
    tariffs : {
        show: true,
        title: i18n.t('message.directionTariffs'),
        sortable: true,
        column: 'tariffs'
    },
    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
