import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import { model } from "./properties/model";

export const mutations = {
    SET_LIST: (state, services) => {              
        state.list = services;        
    },
    SET_INVENTORY: (state, services) => (state.inventory = services),
    SET_CALCULATION_TYPES: (state, calculationTypes) => (state.calculationTypes = calculationTypes),
    SET_SERVICE_CALCULATE_TYPES: (state, serviceCalculateTypes) => (state.serviceCalculateTypes = serviceCalculateTypes),
    SET_DEAL_PRODUCT_SERVICES: (state, dealProductServices) => (state.dealProductServices = dealProductServices),
	SET_SERVICES: (state, services) => (state.services = services),
	SET_SPECIFIC_SERVICES: (state, specificServices) => (state.specificServices = specificServices),
	SET_AUTO_SET_SERVICES: (state, autoSetServices) => (state.autoSetServices = autoSetServices),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, service) => {
        state.model = service;
		  if (service.service_sum) state.model.radio = true;
		  else state.model.radio = false;  
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));        
    },
	EMPTY_SERVICES: (state) => {
            state.services = [];        
    },
    EMPTY_INVENTORY: (state) => {
        state.inventory = [];        
	},
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    EMPTY_LIST: (state) => {
        state.list = [];
    },
    SET_CATEGORY_SERVICES: (state, services) => (state.categoryServices = services),
};
