import { render, staticRenderFns } from "./categoryStatisticBySellingProduct.vue?vue&type=template&id=0d82e291"
import script from "./categoryStatisticBySellingProduct.vue?vue&type=script&lang=js"
export * from "./categoryStatisticBySellingProduct.vue?vue&type=script&lang=js"
import style0 from "./categoryStatisticBySellingProduct.vue?vue&type=style&index=0&id=0d82e291&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports