export const filter = {
    id : '',  
    name: '', 
    service_id: null, 
    service_category_id: null,
    process: '',
    status: '',
    created_at: '',
    updated_at: ''  
  };
  