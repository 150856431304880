export const model = {
    id: null,
    name: '',
    deal_types: [],
    percent: null,
    service_sum: 0,
    calculation_type: null,
    calculate_by: null,
    currency_id: null,
    service_category_id: null,
    show_cabinet: true,
    should_process: false,
    auto_set: false,
    priceList: [],
    created_at: '',
    updated_at: '',
  };
  