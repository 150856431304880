import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    airway_bill_numbers : {
        show: true,
        title: i18n.t('message.airway_bill_numbers'),
        sortable: true,
        column: 'airway_bill_numbers'
    },
    user : {
        show: true,
        title: i18n.t('message.user'),
        sortable: true,
        column: 'user'
    },
    delivery_deal_id : {
        show: false,
        title: i18n.t('message.delivery_id'),
        sortable: true,
        column: 'delivery_deal_id'
    },
    tracking_code : {
        show: true,
        title: i18n.t('message.tracking_code'),
        sortable: true,
        column: 'tracking_code'
    },
    payer : {
        show: false,
        title: i18n.t('message.payer'),
        sortable: true,
        column: 'payer'
    },
    client : {
        show: true,
        title: i18n.t('message.client'),
        sortable: true,
        column: 'client'
    },
    sender : {
        show: true,
        title: i18n.t('message.sender'),
        sortable: true,
        column: 'sender'
    },
    sender_address : {
        show: true,
        title: i18n.t('message.sender_address'),
        sortable: true,
        column: 'sender_address'
    },
    reciever : {
        show: true,
        title: i18n.t('message.reciever'),
        sortable: true,
        column: 'reciever'
    },
    reciever_address : {
        show: true,
        title: i18n.t('message.reciever_address'),
        sortable: true,
        column: 'reciever_address'
    },
    partner : {
        show: true,
        title: i18n.t('message.partner'),
        sortable: true,
        column: 'partner'
    },
    from_filial : {
        show: true,
        title: i18n.t('message.from_filial'),
        sortable: true,
        column: 'from_filial'
    },
    to_filial : {
        show: true,
        title: i18n.t('message.to_filial'),
        sortable: true,
        column: 'to_filial'
    },

    direction_tariff : {
        show: true,
        title: i18n.t('message.directionTariff'),
        sortable: true,
        column: 'direction_tariff'
    },

    begin_date : {
        show: false,
        title: i18n.t('message.begin_date'),
        sortable: true,
        column: 'begin_date'
    },
    end_date : {
        show: false,
        title: i18n.t('message.end_date'),
        sortable: true,
        column: 'end_date'
    },

    deleted_at: {
        show: false,
        title: i18n.t('message.deleted_at'),
        sortable: true,
        column: 'deleted_at'
    },
    reason : {
        show: true,
        title: i18n.t('message.reason'),
        sortable: true,
        column: 'reason'
    },

    products: {
        show: true,
        title: i18n.t('message.products'),
        sortable: true,
        column: 'products'
    },
    
    comment : {
        show: false,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },

    all_weight : {
        show: false,
        title: i18n.t('message.all_weight'),
        sortable: false,
        column: 'all_weight'
    },

    paid_money : {
        show: false,
        title: i18n.t('message.paid_money'),
        sortable: false,
        column: 'paid_money'
    },

    total : {
        show: false,
        title: i18n.t('message.total'),
        sortable: false,
        column: 'total'
    },

    debt : {
        show: false,
        title: i18n.t('message.debt'),
        sortable: false,
        column: 'debt'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
