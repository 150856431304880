export const getters = {
    list: state => state.list,
    inventory: state => state.inventory,
	  services: state => state.services,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort,
    categoryServices: state => state.categoryServices,
    calculationTypes: state => state.calculationTypes,
    serviceCalculateTypes: state => state.serviceCalculateTypes,
    dealProductServices: state => state.dealProductServices,
    specificServices: state => state.specificServices,
    autoSetServices: state => state.autoSetServices,
  };
  