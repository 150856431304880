import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/services',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/services',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/services/inventory",
        method: "get",
        params
    });
}

export function categoryServices(params) {
    return request({
        url: "/services/categoryServices",
        method: "get",
        params
    });
}

export function show(id) {
    return request({
        url: `/services/${id}`,
        method: 'get',
    })
}
export function update(data) {
    return request({
        url: `/services/${data.id}`,
        method: 'put',
        data
    })
}
export function updateDealServices(data) {
	return request({
		 url: `/services/updateDealServices/${data.deal_id}`,
		 method: 'put',
		 data
	})
}
export function destroy(id) {
    return request({
        url: `/services/${id}`,
        method: 'delete',
    })
}

export function deleteDealServiceItem(id) {
	return request({
		 url: `/services/deleteDealServiceItem/${id}`,
		 method: 'delete',
	})
}

export function getCalculationTypes(params) {
    return request({
        url: "/services/getCalculationTypes",
        method: "get",
        params
    });
}

export function getServiceCalculateTypes(params) {
    return request({
        url: "/services/getServiceCalculateTypes",
        method: "get",
        params
    });
}

export function showDealProductServices(params) {
    return request({
        url: `/services/showDealProductServices/${params.id}`,
        method: "get",
        params
    });
}

export function getSpecificServices(params) {
    return request({
        url: `/services/getSpecificServices`,
        method: "get",
        params
    });
}

export function getAutoSetServices(params) {
    return request({
        url: `/services/getAutoSetServices`,
        method: "get",
        params
    });
}
