import {i18n} from '@/utils/i18n';

export const rules = {
    shipping_price_info: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.shipping_price_info')}), trigger: 'change' },
    ],
    delivery_info: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.delivery_info')}), trigger: 'change' },
    ],
};
