import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    user_id : {
        show: true,
        title: i18n.t('message.user_id'),
        sortable: true,
        column: 'user_id'
    },

    from_filial_id : {
        show: true,
        title: i18n.t('message.sender_filial'),
        sortable: true,
        column: 'from_filial_id'
    },

    to_filial_id : {
        show: true,
        title: i18n.t('message.reciever_filial'),
        sortable: true,
        column: 'to_filial_id'
    },

    comment : {
        show: true,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },

    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
