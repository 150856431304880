<template>
  <div v-loading="loadingData">
    <div class="">
      <div class="app-modal_In">
        <div class="app-modal__header95 d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{ `${$t("message.deal")} ${$t("message.n")}${ selectedDealForUpdate.id }` }}
          </p>
          <div>
            <el-button @click="close()" type="warning" plain>{{
              $t("message.close")
            }}</el-button>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div
        class="change-data-wrap p-relative w-100 crm-create-deal mm__tab__color"
      >
        <div class="change-data-card rounded-sm mb-4 mm__tab__color">
          <div class="gc-card__body px-3 py-4 mm__tab__color">
            <div class="deal-tab-wrap">
              <el-tabs v-model="activeName" @tab-click="handleTabClick">
                <el-tab-pane
                  :label="$t('message.overall')"
                  name="first"
                  class="mm__tab__color"
                >
                  <div class="wrap-all d-flex f-between f-wrap">
                    <div class="wrap-all__left input-group-wrap p-3 rounded">
                      <div class="gc-card rounded-sm mb-4 p-relative">
                        <div class="gc-card__header d-flex align-center p-1">
                          <div class="d-flex w-100 ml-2">
                            <div class="p-2">
                              <i v-if="selectedDealForUpdate.cargo_type === 'parcel'" class="el-icon-message" style="color: #0017ff;"></i> 
                              <i v-else class="el-icon-box" style="color: #7f6918;"></i> 
                              <span class="font-bold">
                                {{ $t("message.deal") }}
                              </span>
                            </div>
                            <div class="ml-auto pointer">
                              <el-button @click="downloadDealInfoPDF()" icon="el-icon-s-order" size="mini" type="primary" plain>{{ 'PDF' }}</el-button>
                              <el-button @click="drawerUpdateDeal = true" icon="el-icon-edit" size="mini" type="primary" plain>{{ $t("message.update") }}</el-button>   
                            </div>
                          </div>
                        </div>

                        <div class="gc-card__body px-3 py-2">
                          <div class="text-descr">
                            <span class="text-descr__label d-block">{{ $t("message.tracking_code") }}</span>
                            <div class="d-flex align-center font-medium">
                              {{ selectedDealForUpdate.tracking_code }}
                            </div>
                          </div>
                        </div>

                        <div class="gc-card__body px-3 py-2" v-if="selectedDealForUpdate.payer">
                          <div class="text-descr">
                            <span class="text-descr__label d-block">{{ $t("message.payer") }}</span>
                            <div class="d-flex align-center font-medium">
                              <span v-if=" selectedDealForUpdate.payer && selectedDealForUpdate.payer.custom_id" >
                                ID: {{ selectedDealForUpdate.payer ? selectedDealForUpdate.payer.custom_id : "" }} ,
                              </span>
                              <span>
                                {{ selectedDealForUpdate.payer ? selectedDealForUpdate.payer.name : "" }}
                              </span>
                              <span v-if="selectedDealForUpdate.payer.phone">
                                , {{ selectedDealForUpdate.payer.phone }}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div v-if="selectedDealForUpdate.client" class="gc-card__body px-3 py-2">
                          <div class="text-descr">
                            <span class="text-descr__label d-block">{{
                              $t("message.sender")
                            }}</span>
                            <div class="d-flex align-center font-medium">
                              <span v-if="selectedDealForUpdate.client && selectedDealForUpdate.client.custom_id">
                                ID: {{ selectedDealForUpdate.client ? selectedDealForUpdate.client.custom_id : "" }} ,
                              </span>
                              <span> {{ selectedDealForUpdate.client ? selectedDealForUpdate.client.name : ""}} </span>
                              <span>
                                {{ selectedDealForUpdate.deal_type_id !== 1 ? ", " + selectedDealForUpdate.phone : "" }}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="gc-card__body px-3 py-2" v-if="selectedDealForUpdate.reciever" >
                          <div class="text-descr">
                            <span class="text-descr__label d-block">{{
                              $t("message.reciever")
                            }}</span>
                            <div class="d-flex align-center font-medium">
                              <span v-if="selectedDealForUpdate.reciever && selectedDealForUpdate.reciever.custom_id">
                                ID: {{ selectedDealForUpdate.reciever ? selectedDealForUpdate.reciever.custom_id : "" }} ,
                              </span>
                              <span> {{ selectedDealForUpdate.reciever ? selectedDealForUpdate.reciever.name : "" }}
                              </span>
                              <span v-if="selectedDealForUpdate.to_client_phone" >
                                , {{ selectedDealForUpdate.to_client_phone }}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="gc-card__body px-3 py-2" v-if="selectedDealForUpdate.partner">
                          <div class="text-descr">
                            <span class="text-descr__label d-block">{{ $t("message.partner") }}</span>
                            <div class="d-flex align-center font-medium">
                              <span v-if=" selectedDealForUpdate.partner && selectedDealForUpdate.partner.custom_id" >
                                ID: {{ selectedDealForUpdate.partner ? selectedDealForUpdate.partner.custom_id : "" }} ,
                              </span>
                              <span>
                                {{ selectedDealForUpdate.partner ? selectedDealForUpdate.partner.name : "" }}
                              </span>
                              <span v-if="selectedDealForUpdate.partner_phone">
                                , {{ selectedDealForUpdate.partner_phone }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="gc-card__body px-3 py-2"
                          v-if="
                            selectedDealForUpdate.deal_type_id === 1 &&
                            selectedDealForUpdate.client_deal_address
                          "
                        >
                          <div class="text-descr">
                            <span class="text-descr__label d-block">{{
                              $t("message.reciever_address")
                            }}</span>
                            <div class="d-flex align-center font-medium">
                              <span>
                                {{ selectedDealForUpdate.client_deal_address }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="gc-card__body px-3 py-2"
                          v-if="
                            selectedDealForUpdate.deal_type_id !== 1 &&
                            selectedDealForUpdate.client_deal_address
                          "
                        >
                          <div class="text-descr">
                            <span class="text-descr__label d-block">{{
                              $t("message.sender_address")
                            }}</span>
                            <div class="d-flex align-center font-medium">
                              <span>
                                {{ selectedDealForUpdate.client_deal_address }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="gc-card__body px-3 py-2"
                          v-if="
                            selectedDealForUpdate.deal_type_id !== 1 &&
                            selectedDealForUpdate.to_client_deal_address
                          "
                        >
                          <div class="text-descr">
                            <span class="text-descr__label d-block">{{
                              $t("message.reciever_address")
                            }}</span>
                            <div class="d-flex align-center font-medium">
                              <span>
                                {{
                                  selectedDealForUpdate.to_client_deal_address
                                }}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          class="gc-card__body px-3 py-2"
                          v-if="selectedDealForUpdate.from_filial"
                        >
                          <div class="text-descr">
                            <span class="text-descr__label d-block">{{
                              $t("message.from_filial")
                            }}</span>
                            <div class="d-flex align-center font-medium">
                              {{ selectedDealForUpdate.from_filial ? selectedDealForUpdate.from_filial.name : "" }}
                              <span v-if="selectedDealForUpdate.from_filial.location_name">
                                | {{ selectedDealForUpdate.from_filial.location_name}}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          class="gc-card__body px-3 py-2"
                          v-if="selectedDealForUpdate.to_filial"
                        >
                          <div class="text-descr">
                            <span class="text-descr__label d-block">{{
                              $t("message.to_filial")
                            }}</span>
                            <div class="d-flex align-center font-medium">
                              {{ selectedDealForUpdate.to_filial ? selectedDealForUpdate.to_filial.name : "" }}
                              <span v-if="selectedDealForUpdate.to_filial.location_name">
                                | {{ selectedDealForUpdate.to_filial.location_name }}
                              </span >
                            </div>
                          </div>
                        </div>

                        <div
                          class="gc-card__body px-3 py-2"
                          v-if="selectedDealForUpdate.directionTariff"
                        >
                          <div class="text-descr">
                            <span class="text-descr__label d-block">{{
                              $t("message.directionTariff")
                            }}</span>
                            <div class="d-flex align-center font-medium">
                              {{ selectedDealForUpdate.directionTariff ? selectedDealForUpdate.directionTariff.name : "" }}
                            </div>
                          </div>
                        </div>

                        <div class="gc-card__body px-3 py-2">
                          <div class="text-descr">
                            <span class="text-descr__label d-block">{{ $t("message.min_shipping_weight") }}</span>
                            <div class="d-flex align-center font-medium">
                              {{ selectedDealForUpdate.minimum_weight }} {{ $t('message.kg') }}
                            </div>
                          </div>
                        </div>

                        <div class="gc-card__body px-3 py-2">
                          <div class="text-descr">
                            <div class="filterblock_s mr-5">
                              <div>
                                <span class="text-descr__label d-block">
                                  {{ $t("message.shipping_total") }}
                                </span>
                                <div class="d-flex align-center font-medium">
                                  {{ selectedDealForUpdate.product_total| formatMoney(1) }}
                                </div>
                              </div>
                              <div>
                                <span class="text-descr__label d-block">
                                  {{ $t("message.money_amount") + " " + $t("message.services") }}
                                </span>
                                <div class="d-flex align-center font-medium">
                                  {{ selectedDealForUpdate.total_services_price | formatMoney(1) }}
                                </div>
                              </div>
                              <div v-if="selectedDealForUpdate.cargo_type != 'parcel' && selectedDealForUpdate.shipping_service">
                                <span class="text-descr__label d-block">
                                  {{ $t("message.money_amount") + " " + $t("message.deliveries") }}
                                </span>
                                <div class="d-flex align-center font-medium">
                                  {{ deliveryTotal | formatMoney(1) }}
                                </div>
                              </div>
                              <div>
                                <span class="text-descr__label d-block">
                                  {{ $t("message.total_amount") }}
                                </span>
                                <div class="d-flex align-center font-medium">
                                  {{ ((selectedDealForUpdate.product_total ? selectedDealForUpdate.product_total : 0) + selectedDealForUpdate.total_services_price + deliveryTotal) | formatMoney(1) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-show="selectedDealForUpdate.incoming_product_money" class="gc-card__body px-3 py-2">
                          <div class="text-descr">
                            <span class="text-descr__label d-block">{{
                              $t("message.goods_in_circulation")
                            }}</span>
                            <div class="d-flex align-center font-medium">
                              {{ selectedDealForUpdate.incoming_product_money | formatMoney(1) }}
                            </div>
                          </div>
                        </div>

                        <div v-show="selectedDealForUpdate.begin_date" class="gc-card__body px-3 py-2">
                          <div class="filterblock_s mr-5">
                            <div>
                              <span class="text-descr__label d-block">
                                {{ $t("message.begin_date") }}
                              </span>
                              <div class="d-flex align-center font-medium">
                                {{ formatDate(selectedDealForUpdate.begin_date) }}
                              </div>
                            </div>
                            <div>
                              <span class="text-descr__label d-block">
                                {{ $t("message.end_date") }}
                              </span>
                              <div class="d-flex align-center font-medium">
                                {{ formatDate(selectedDealForUpdate.end_date) }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-show="selectedDealForUpdate.email" class="gc-card__body px-3 py-2">
                          <div class="text-descr mb-3">
                            <span class="text-descr__label d-block">{{  $t("message.email") }}</span>
                            <div class="d-flex align-center font-medium">
                              {{ selectedDealForUpdate.email ? selectedDealForUpdate.email : "" }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="gc-card rounded-sm mb-4">
                        <div
                          class="gc-card__header d-flex align-center px-3 py-4"
                        >
                          <i class="el-icon-files mr-2"></i>
                          <div class="d-flex w-100">
                            <span class="font-bold">
                              {{ $t("message.additional") }}
                            </span>
                            <div class="change-form ml-auto pointer"></div>
                          </div>
                        </div>
                        <div class="gc-card__body px-3 py-4">
                          <div class="text-descr">
                            <span class="text-descr__label d-block">
                              {{ $t("message.cargo") }}
                            </span>
                            <div class="d-flex align-center font-medium">
                                <span class="text-descr__text f-fill text-reset">
                                  {{ $t('message.number_of_cargo')}} : {{ additional.total_all_amount_count | formatNumber(1) }} шт
                                </span>
								                <span class="text-descr__text f-fill text-reset">
                                  {{ $t('message.all_weight') }} : 
                                  {{ additional.total_all_weigth | formatNumber(1) }} / {{ additional.sum_coming_weight | formatNumber(1) }} {{$t("message.kg")}}
                                </span>
                            </div>
                          </div>
                          <div v-if="selectedDealForUpdate.cargo_type !== 'parcel'" class="text-descr">
                            <span class="text-descr__label d-block">
                              {{ $t("message.weight_delivery") }}
                            </span>
                            <div class="d-flex align-center font-medium">
                                <span class="text-descr__text f-fill text-reset">
                                    {{ $t("message.package") }} : {{additional.package.dp_weight | formatNumber(1)}} / 
                                    {{additional.package.weight}} {{ $t("message.kg") }} ( {{ additional.package.count | formatNumber(1) }} шт )
                                </span>
                                <span class="text-descr__text f-fill text-reset">
                                    {{ $t("message.product") }} : {{ additional.product.dp_weight | formatNumber(1)}} /
                                    {{additional.product.weight | formatNumber(1)}} 
                                    {{ $t("message.kg") }}   ( {{ additional.product.count | formatNumber(1) }} шт )
                                </span>
                            </div>
                          </div>
                          <div v-if="selectedDealForUpdate.cargo_type !== 'parcel'" class="gc-card__body py-2">
                            <div>
                              <span class="text-descr__label">
                                {{ $t("message.packages") }}
                              </span>
                            </div>
                            <template>
                              <el-table
                                v-if="additional.packages.length > 0"
                                :data="additional.packages"
                                style="width: 100%"
                              >
                                <el-table-column
                                  prop="key"
                                  :label="$t('message.containerType')"
                                  width="180"
                                >
                                </el-table-column>
                                <el-table-column
                                  prop="count"
                                  :label="$t('message.count_product')"
                                  width="180"
                                >
                                </el-table-column>
                                <el-table-column
                                  prop="pc_weight"
                                  :label="$t('message.actual_weight')"
                                >
                                </el-table-column>
								                <el-table-column
                                  prop="dp_weight"
                                  :label="$t('message.deal_weight')"
                                >
                                </el-table-column>
                              </el-table>
                            </template>
                          </div>

                          <div v-show="selectedDealForUpdate.comment" class="text-descr">
                            <span class="text-descr__label d-block">
                              {{ $t("message.comment") }}
                            </span>
                            <div class="d-flex align-center font-medium">
                              <span
                                class="text-descr__text f-fill text-reset"
                                >{{ selectedDealForUpdate.comment }}</span
                              >
                            </div>
                          </div>

                          <div v-show="selectedDealForUpdate.files && selectedDealForUpdate.files.length > 0" class="text-descr">
                            <span class="text-descr__label d-block">
                              {{ $t("message.files") }}
                            </span>
                            <div
                              @click="
                                downloadFile(
                                  selectedDealForUpdate.id,
                                  file.name
                                )
                              "
                              class="d-flex align-center mir font-medium pb-2"
                              v-for="file in selectedDealForUpdate.files"
                              :key="file.name"
                            >
                              <span class="text-descr__text text-reset">
                                <i class="el-icon-document-checked"></i>
                                {{ file ? file.name : "" }}
                              </span>
                              <span class="text-descr__text text-reset">
                                <i class="el-icon-download"></i>
                              </span>
                            </div>
                          </div>

                          <div class="text-descr">
                            <span class="text-descr__label d-block">
                              {{ $t("message.responsible") }}
                            </span>
                            <div
                              class="responsible-block d-flex align-center font-medium p-2 rounded-sm"
                            >
                              <div
                                class="gc-small-block rounded-circle hidden mr-3"
                                style="border: 1px solid gray"
                              >
                                <img
                                  :src="
                                    selectedDealForUpdate.user
                                      ? selectedDealForUpdate.user.avatar
                                        ? selectedDealForUpdate.user.avatar
                                        : '/img/default-user-icon.png'
                                      : '/img/default-user-icon.png'
                                  "
                                  alt="Default Avatar"
                                  class="d-block"
                                />
                              </div>
                              <div class="f-fill">
                                <span class="text-descr__text f-fill">
                                  {{
                                    selectedDealForUpdate.user
                                      ? selectedDealForUpdate.user.name
                                      : ""
                                  }}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div v-show="selectedDealForUpdate.spectators && selectedDealForUpdate.spectators.length > 0" class="text-descr">
                            <span class="text-descr__label d-block">
                              {{ $t("message.spectators") }}
                            </span>
                            <div
                              v-for="spectator in selectedDealForUpdate.spectators"
                              :key="spectator.id"
                              class="responsible-block d-flex align-center font-medium p-2 rounded-sm"
                            >
                              <div
                                class="gc-small-block rounded-circle hidden mr-3"
                                style="border: 1px solid gray"
                              >
                                <img
                                  :src="
                                    spectator
                                      ? spectator.avatar
                                        ? spectator.avatar
                                        : '/img/default-user-icon.png'
                                      : '/img/default-user-icon.png'
                                  "
                                  alt="Default Avatar"
                                  class="d-block"
                                />
                              </div>
                              <div class="f-fill mirdel">
                                <span class="text-descr__text f-fill">
                                  {{ spectator ? spectator.name : "" }}
                                </span>
                                <span
                                  class="text-descr__text text-reset"
                                  @click="
                                    deletingSpectator(
                                      spectator ? spectator.id : ''
                                    )
                                  "
                                >
                                  <i
                                    class="el-icon-delete"
                                    style="color: red"
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="wrap-all__right input-group-wrap p-3 rounded">
                      <deal-correspondences
                        v-can="'dealCorrespondences.index'"
                        ref="dealCorrespondence"
                        @listChanged="listChanged"
                        :deal_id="selectedDealForUpdate.id"
                        :deal_email="selectedDealForUpdate.email"
                      ></deal-correspondences>
                    </div>
                  </div>

                  <div class="mt-5">
                    <el-row class="mt-5">
                      <el-button
                        type="danger"
                        @click="drawerDeleteDeal = true"
                        v-if="
                          permissions.some((per) => per.slug == 'deals.delete')
                        "
                        >{{ $t("message.delete_deal") }}</el-button
                      >
                    </el-row>
                  </div>
                </el-tab-pane>

                <el-tab-pane
                  :label="$t('message.products')"
                  name="second"
                  class="mm__tab__color"
                >
                  <div v-if="selectedDealForUpdate.cargo_type === 'parcel'">
                    <parcel-products
                      ref="parcelProducts"
                      v-model="selectedDealForUpdate.parcel"
                      :mainForm="dealModel.parcel"
                      :shipping_service="dealModel.shipping_service"
                      :deal_id="dealModel.id"
                    >
                    </parcel-products>
                  </div>
                  <div v-else>
                    <deal-products
                      ref="dealProducts"
                      v-model="selectedDealForUpdate.products"
                      :deal_packages="selectedDealForUpdate.packages"
                      :deal_products="selectedDealForUpdate.products"
                      @updateDealPackages="updateFormPackages"
                      :direction_tariff_id="selectedDealForUpdate.direction_tariff_id"
                      :deal_id="selectedDealForUpdate.id"
                    >
                    </deal-products>
                  </div>

                  <el-row class="mt-5">
                    <el-button
                      type="primary"
                      @click="submitProducts()"
                      size="small"
                      round
                      v-loading="loadingButton"
                      v-if="
                        permissions.some(
                          (per) => per.slug == 'dealProducts.create'
                        )
                      "
                    >
                      {{ $t("message.save") }}
                    </el-button>
                  </el-row>
                </el-tab-pane>

                <el-tab-pane
                  :label="$t('message.services')"
                  name="thrid"
                  class="mm__tab__color"
                >
                  <crm-services
                    :cargo_type="selectedDealForUpdate.cargo_type"
                    :services="!dealEdit ? services : []"
                    :parcel="selectedDealForUpdate.parcel"
                    :allItems="allItems"
                    :direction_tariff_id="selectedDealForUpdate.direction_tariff_id"
                    :partner_id="selectedDealForUpdate.partner_client_id"
                    :deal_services="selectedDealForUpdate.services"
                  ></crm-services>

                  <el-row class="mt-5">
                    <el-button
                      type="primary"
                      @click="submitDealServices()"
                      size="small"
                      round
                      :loading="loadingButton"
                      v-if="permissions.some((per) => per.slug == 'services.create')"
                    >
                      {{ $t("message.save") }}
                    </el-button>
                  </el-row>
                </el-tab-pane>

                <el-tab-pane
                  :label="$t('message.products_locations')"
                  name="seven"
                  class="mm__tab__color"
                >
                  <el-table
                    v-loading="loadingProdLocations"
                    class="rounded-sm"
                    :data="dealProductsLocation"
                    border
                    style="width: 100%"
                  >
                    <el-table-column :label="$t('message.name')">
                      <template slot-scope="scope">
                        {{ scope.row.product ? scope.row.product.name : "" }}
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.weight_in_kg')">
                      <template slot-scope="scope">
                        {{ scope.row.weight }}
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.total_quantity')">
                      <template slot-scope="scope">
                        {{ scope.row.quantity }}
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.from_filial')">
                      <template slot-scope="scope">
                        {{ scope.row.from_filial_remainder }}
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.packing')">
                      <template slot-scope="scope">
                        {{ scope.row.packing }}
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.on_flight')">
                      <template slot-scope="scope">
                        {{ scope.row.on_flight }}
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.unpacking')">
                      <template slot-scope="scope">
                        {{ scope.row.unpacking }}
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.to_filial')">
                      <template slot-scope="scope">
                        {{ scope.row.to_filial_remainder }}
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.client')">
                      <template slot-scope="scope">
                        {{ scope.row.client_remainder }}
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>

                <el-tab-pane
                  :label="$t('message.pay')"
                  name="fourth"
                  class="mm__tab__color"
                >
                  <el-table :data="paidMoneyList" stripe style="width: 100%">
                    <el-table-column
                      prop="id"
                      :label="$t('message.n')"
                      width="80"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="paymentType"
                      :label="$t('message.paymentType')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="bankAccount"
                      :label="$t('message.bankAccount')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="money_amount"
                      :label="$t('message.money_amount')"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.money_amount | formatNumber(1) }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="currency_rate"
                      :label="$t('message.rate')"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.currency_rate | formatNumber(1) }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="currency"
                      :label="$t('message.currency')"
                    >
                    </el-table-column>
                    <el-table-column :label="$t('message.total')">
                      <template slot-scope="scope">
                        {{ (scope.row.money_amount * scope.row.currency_rate) | formatMoney(1) }}
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <h3>
                        {{ $t("message.total") }}
                        {{ ((selectedDealForUpdate.product_total ? selectedDealForUpdate.product_total : 0) + selectedDealForUpdate.total_services_price + deliveryTotal) | formatMoney(1) }}
                      </h3>
                    </el-col>
                    <el-col :span="8">
                      <h3>
                        {{ $t("message.paid_money") }}:
                        {{ selectedDealForUpdate.paid_money | formatMoney(1) }}
                      </h3>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane
                  v-if="permissions.some((per) => per.slug == 'dealHistory.index')"
                  :label="$t('message.history')"
                  name="history"
                  class="mm__tab__color"
                >
                  <DealHistory :deal_id="selectedDealForUpdate.id" :activeName="activeName"></DealHistory>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      class="KP_modal"
      :title="$t('message.delete')"
      :visible.sync="drawerDeleteDeal"
      :append-to-body="true"
      width="30%"
      center
      :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
      ref="drawerDeleteDeal"
      @opened="drawerOpened('deleteDealComponent')"
      @closed="drawerClosed('deleteDealComponent')"
    >
      <delete-deal
        ref="dealOfferComponent"
        :deal_id="selectedDealForUpdate ? selectedDealForUpdate.id : ''"
        @deleteDealClose="deleteDealClose"
        drawer="drawerDealOfferCreate"
      >
      </delete-deal>
    </el-dialog>

    <div class="app-modal app-modal__full modal-widt-70">
      <el-drawer
        :append-to-body="true"
        size="95%"
        :with-header="false"
        :visible.sync="drawerCreateDeal"
        class="body_scroll bg-se"
        ref="drawerDealCreate"
        :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
        @opened="drawerOpened('drawerDealCreateChild')"
        @closed="drawerClosed('drawerDealCreateChild')"
      >
        <crm-create-deal
          :cargo_type="cargo_type"
          ref="drawerDealCreateChild"
          drawer="drawerDealCreate"
        ></crm-create-deal>
      </el-drawer>

      <el-drawer
        :append-to-body="true"
        size="95%"
        :with-header="false"
        :visible.sync="drawerUpdateDeal"
        class="body_scroll bg-se"
        :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
        ref="drawerDealUpdate"
        @opened="drawerOpened('drawerDealUpdateChild')"
        @closed="drawerClosed('drawerDealUpdateChild')"
      >
        <crm-update-deal
          :selectedItem="selectedDealForUpdate"
          :dealEdit="dealEdit"
          ref="drawerDealUpdateChild"
          drawer="drawerDealUpdate"
        ></crm-update-deal>
      </el-drawer>

      <el-drawer
        :append-to-body="true"
        :with-header="false"
        :visible.sync="drawerUpdateClient"
        class="bg-se"
        :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
        size="70%"
        ref="drawerUpdateClient"
        @opened="drawerOpened('drawerUpdateClientChild')"
        @closed="drawerClosed('drawerUpdateClientChild')"
      >
        <crm-update-client
          :selectedItem="
            selectedDealForUpdate.client ? selectedDealForUpdate.client : null
          "
          ref="drawerUpdateClientChild"
          drawer="drawerUpdateClient"
        ></crm-update-client>
      </el-drawer>

      <el-dialog
        :title="$t('message.update_payment')"
        :visible.sync="paymentDrawer"
        width="30%"
        :append-to-body="true"
        class="add-participants bg-se"
        :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
        ref="drawerPayments"
        @opened="drawerOpened('drawerPaymentsChild')"
        @closed="drawerClosed('drawerPaymentsChild')"
      >
        <CrmUpdatePayment ref="drawerPaymentsChild" drawer="drawerPayments">
        </CrmUpdatePayment>
      </el-dialog>
      <!-- end AddingTask -->
    </div>

    <div class="app-modal app-modal__full">
      <el-drawer
        class="bg-se"
        :class="mode ? 'l-modal-style' : 'd-modal-style'"
        :visible.sync="drawerTaskCreate"
        :with-header="false"
        ref="drawerTaskCreate"
        @opened="drawerOpened('drawerTaskCreateChild')"
        @closed="drawerClosed('drawerTaskCreateChild')"
        :append-to-body="true"
        size="80%"
      >
        <CreateTask
          :selected_deal="selectedDealForUpdate"
          :drawerTaskCreate="drawerTaskCreate"
          ref="drawerTaskCreateChild"
          drawer="drawerTaskCreate"
        />
      </el-drawer>
    </div>
  </div>
</template>

<script>
import DealOffers from "./dealOffer/deal-offers";
import DealTasks from "./dealTask/deal-tasks";
import DealProducts from "./dealProduct/deal-product-list-with-packages";
import ParcelProducts from "./dealProduct/parcel-products";
import DealCorrespondences from "./dealCorrespondence/deal-correspondences.vue";
import DealHistory from "./dealHistory/dealHistory.vue";

import CrmCreateDeal from "./deal/crm-create-deal";
import CrmUpdateDeal from "./deal/crm-update-deal";
import DeleteDeal from "./deleteDeal/delete-deal";
import CrmUpdateClient from "@/views/clients/components/crm-update";
import CrmUpdatePayment from "./payments/crm-update-payment";
import CrmServices from "./services/crm-services";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  name: "Modal1",
  mixins: [drawer, form],

  components: {
    DealTasks,
    DealOffers,
    DealProducts,
    CrmCreateDeal,
    CrmUpdateDeal,
    DealCorrespondences,
    DeleteDeal,
    CrmUpdateClient,
    CreateTask: () => import("@/views/tasks/components/add-modal"),
    CrmUpdatePayment,
    ParcelProducts,
    CrmServices,
    DealHistory
  },
  props: {
    selectedDeal: {
      default: null,
      type: Object,
    },
    dealCreate: {
      default: null,
    },
    dealEdit: {
      default: false,
    },
    cargo_type: {
      default: "",
    },
  },
  data() {
    return {
      maxSize: 300,
      createDealOffer: false,
      loadingProdLocations: false,
      loadingButton: false,
      form: {},
      stage_id: null,
      loadingData: false,
      drawerUpdateClient: false,
      drawerCreateDeal: false,
      drawerUpdateDeal: false,
      drawerDeleteDeal: false,
      activeName: "first",
      createDealOffer: false,
      reloadList: false,
      selectedDealForUpdate: {},
      selectedUsersList: [],
      selectedUsersContainer: false,
      drawerTaskCreate: false,
      drawerTaskCreateChild: false,
      paymentDrawer: false,
      customColors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#6f7ad3", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#5cb87a", percentage: 100 },
      ],
      // afterLeaveWorked: false,
      afterOpenWroked: false,
      allItems: [],
    };
  },
  watch: {
    activeName: {
      handler: async function (newVal, oldVal) {
        
      },
      deep: true,
      immediate: true,
    },
    selectedDealForUpdate: {
      handler: function (data) {
        if (data.hasOwnProperty("products") && data.hasOwnProperty("packages") ) {
          this.allItems = data.products.concat(data.packages);
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      permissions: "auth/permissions",
      getNewDeal: "deals/newForm",
      dealModel: "deals/model",
      paidMoneyList: "deals/paidMoneyList",
      dealProductsLocation: "dealProducts/dealProducts",
      services: "services/inventory",
      formServices: "services/services",
      calculationTypes: 'products/calculation_types',
      mode: "MODE",
    }),
    deliveryTotal(){
      return (this.selectedDealForUpdate.delivery_price && this.selectedDealForUpdate.shipping_service) ? this.additional.total_all_weigth * this.selectedDealForUpdate.delivery_price : 0;
    },
    total_weight() {
      let total = 0;
      if (this.selectedDealForUpdate.products.length > 0) {
        total = this.selectedDealForUpdate.products
          .map((item) => item.weight)
          .reduce((a, b) => a + b, 0);
      }
      return total + " kg";
    },
    totalPaymentPercentage() {
      let percentage = 0;
      if (
        this.selectedDealForUpdate.deal_payments &&
        this.selectedDealForUpdate.deal_payments.length > 0
      ) {
        percentage = this.selectedDealForUpdate.deal_payments
          .map((item) => item.percentage)
          .reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
      }
      return Math.round(percentage);
    },
    additional() {
      let data = {};
      if(this.selectedDealForUpdate.cargo_type == 'parcel'){
        let parcel = {};
        if (this.selectedDealForUpdate.hasOwnProperty("parcel")) {
          parcel = this.selectedDealForUpdate.parcel;
          data.total_all_amount_count = 1;
          data.total_all_weigth = parcel.weight;
          data.sum_coming_weight = parcel.sum_coming_weight;
        }
      }else{
        let total_packages_weight = 0, total_packages_dp_weight = 0;
        let total_products_weight = 0, total_products_dp_weight = 0;
        let total_all_amount_count = _.size(this.dealModel.packages) + _.sumBy(this.dealModel.products, "quantity");
        let packages = [];

        if (!_.isEmpty(this.dealModel.packages)) {
          this.dealModel.packages.forEach((item) => {
            total_packages_weight += parseFloat(item.pc_weight);
            total_packages_dp_weight += parseFloat(item.dp_weight);
          });

          let grouped = _.groupBy(this.dealModel.packages, "container_type.name");
          packages = _.map(grouped, (value, key) => {
            return {
              key: key,
              count: value.length,
              dp_weight: _.round(_.sumBy(value, (item) => +parseFloat(item.dp_weight)), 3),
              pc_weight: _.round(_.sumBy(value, (item) => +parseFloat(item.pc_weight)), 3),
            };
          });
        }
        if (!_.isEmpty(this.dealModel.products)) {
          total_products_weight = _.sumBy(
            this.dealModel.products,
            (product) => +parseFloat(product.sum_coming_weight)
          );
          total_products_dp_weight = _.sumBy(
            this.dealModel.products,
            (product) => +product.weight
          );
        }
        data.total_all_amount_count = total_all_amount_count;
        data.sum_coming_weight = total_packages_weight + total_products_weight;
        data.total_all_weigth = total_products_dp_weight + total_packages_dp_weight;

        data.package = {
          count: _.size(this.dealModel.packages),
          weight: _.round(total_packages_weight, 3),
          dp_weight: _.round(total_packages_dp_weight, 3)
        };
        (data.product = {
          count: _.sumBy(this.dealModel.products, "quantity"),
          weight: total_products_weight,
          dp_weight: total_products_dp_weight,
        }),
          (data.packages = packages);
      }

      return data;
    },
    maxWeight() {
      let max_weight = this.selectedDealForUpdate.shipping_service ? 30 : 20;
      return max_weight;
    },
    totalSMSizeSum() {
      if (this.selectedDealForUpdate.parcel) {
        let total =
          parseFloat(this.selectedDealForUpdate.parcel.width || 0) +
          parseFloat(this.selectedDealForUpdate.parcel.height || 0) +
          parseFloat(this.selectedDealForUpdate.parcel.length || 0);
        return _.round(total, 1);
      }
      return 0;
    },
    totalServiceSum() {
      let total_product_money = 0;
      let total_services_money = 0;

      let products = [];
      if (this.selectedDealForUpdate.hasOwnProperty("products") && this.selectedDealForUpdate.hasOwnProperty("packages")) {
        products = this.selectedDealForUpdate.products.concat(this.selectedDealForUpdate.packages);
      }
      let parcel = {};
      if (this.selectedDealForUpdate.hasOwnProperty("parcel")) {
        parcel = this.selectedDealForUpdate.parcel;
      }
      let services = [];
      if (this.selectedDealForUpdate.hasOwnProperty("services")) {
        services = this.selectedDealForUpdate.services;
      }

      if (this.selectedDealForUpdate.cargo_type == "commercial") {
        if (!_.isEmpty(products)) {
          total_product_money = _.sumBy(products,(item) => +parseFloat(item.product_price));
        }
      } else {
        if (!_.isEmpty(parcel.parcel_products)) {
          total_product_money = _.sumBy(parcel.parcel_products,(item) => +parseFloat(item.product_price));
        }
      }

      if (!_.isEmpty(services)) {
        services.forEach((item) => {
          if (item.percent) {
            total_services_money += _.round((item.percent / 100) * total_product_money, 2);
          } else {
            total_services_money += _.round(item.service_sum * item.rate, 2);
          }
        });
      }

      return total_services_money;
    },
  },
  methods: {
    ...mapActions({
      updateList: "deals/index",
      downloadDealInfo: "deals/downloadDealInfo",
      setPagination: "deals/setPagination",
      updateSort: "deals/updateSort",
      updateFilter: "deals/updateFilter",
      updateColumn: "deals/updateColumn",
      updatePagination: "deals/updatePagination",
      show: "deals/show",
      showProdLocations: "dealProducts/show",
      refreshData: "deals/refreshData",
      showLastDeal: "deals/showLast",
      empty: "deals/empty",
      downloadDealFile: "deals/downloadDealFile",
      archivateDeal: "deals/archivateDeal",
      delete: "deals/destroy",
      deleteSpectator: "deals/deleteSpectator",
      updatePaidMoneyList: "deals/dealPaidMoney",
      updateFilterOptions: "deals/getFilterOptions",
      save: "dealProducts/store",
      update: "dealProducts/update",
      dealOffers: "dealOffers/index",
      emptyDealCorrespondences: "dealCorrespondences/emptyList",
      updateDealServices: "services/updateDealServices",
      getServicesByDealType: "services/inventory",
      getCalculationTypes: 'products/getCalculationTypes',
    }),
    downloadDealInfoPDF(){
      this.downloadDealInfo(this.selectedDealForUpdate.id)
        .then((res) => {
          const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
          WinPrint.document.write(res.data);
          WinPrint.document.close();
          WinPrint.focus();
          setTimeout(() => {
              WinPrint.print();
              WinPrint.close();
          }, 1000);
        }).catch((err) => {
          this.$alert(err);
        });
    },
    handleTabClick(tab, event) {
      if (tab.name == "fourth") {
        this.updatePaidMoneyList(this.selectedDealForUpdate.id);
      }
      if (tab.name == "seven") {
        this.loadingProdLocations = true;
        this.showProdLocations({ id: this.selectedDealForUpdate.id })
          .then((res) => {
            this.loadingProdLocations = false;
          })
          .catch((err) => {
            this.loadingProdLocations = false;
          });
      }
    },

    updateFormPackages(deal_packs) {
      this.$set(this.selectedDealForUpdate, "packages", deal_packs);
    },

    addTaskToDeal(deal_id) {
      this.drawerTaskCreate = true;
    },

    dealOffersClose() {
      this.createDealOffer = false;
    },
    deleteDealClose(val) {
      this.drawerDeleteDeal = false;
      if (val) {
        this.close();
        this.updateFilterOptions();
      }
    },
    async updateCalculationList() {
      if(this.calculationTypes.length == 0){
        await this.getCalculationTypes();
      }
    },

    async afterOpen() {
      await this.updateCalculationList();
      if (this.selectedDeal && this.selectedDeal.id) {
        if (this.$refs["drawerDealUpdateChild"]) {
          this.$refs["drawerDealUpdateChild"].reloadTariff();
        }
        if (this.dealEdit) {
          this.selectedDealForUpdate = JSON.parse(JSON.stringify(this.selectedDeal));
          this.drawerUpdateDeal = JSON.parse(JSON.stringify(this.dealEdit));
        } else {
          await this.fetchData(this.selectedDeal.id);
        }
      } else {
        this.selectedDealForUpdate = JSON.parse(JSON.stringify(this.dealModel));
        this.drawerUpdateDeal = JSON.parse(JSON.stringify(this.dealEdit));
      }
      this.drawerCreateDeal = JSON.parse(JSON.stringify(this.dealCreate));

      let type = "";
      if (this.drawerCreateDeal) {
        type = this.cargo_type;
      } else {
        type = this.selectedDeal.cargo_type;
      }
      this.getServicesByDealType({ deal_type: type });
    },

    fetchData(id) {
      this.loadingData = true;
      this.show(id)
        .then((res) => {
          this.selectedDealForUpdate = JSON.parse(JSON.stringify(this.dealModel));
          this.parent().dealEdit = false;
          if (this.selectedDealForUpdate.deal_stage) {
            this.stage_id = this.selectedDealForUpdate.deal_stage.id;
          }
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
    deletingSpectator(user_id) {
      if (this.selectedDealForUpdate.id && user_id) {
        let deal_id = this.selectedDealForUpdate.id;
        this.$confirm(
          this.$t("message.do_you_want_to_delete_participant"),
          this.$t("message.warning"),
          {
            confirmButtonText: this.$t("message.yes"),
            cancelButtonText: this.$t("message.no"),
            type: "warning",
          }
        )
          .then(() => {
            this.deleteSpectator({
              deal_id: deal_id,
              user_id: user_id,
            }).then(() => {
              this.fetchData(deal_id);
            });
          })
          .catch(() => {
            this.$message({
              type: "warning",
              message: this.$t("message.operation_canceled"),
            });
          });
      }
    },

    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened();
        }
      }
    },
    closeDrawer(drawer) {
      if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
        this.$refs[drawer].closeDrawer();
      }
    },
    dealFirstCreated() {
      this.showLastDeal().then((res) => {
        this.listChanged();
        this.selectedDealForUpdate = JSON.parse(JSON.stringify(this.dealModel)); //this.getNewDeal
      });
    },
    drawerClosed(ref) {
      if ((this.$refs.drawerDealCreateChild, ref === "drawerDealCreateChild")) {
        if (!this.$refs.drawerDealCreateChild.dataSaved) {
          this.parent().closeAddModel();
        }
      }

      if (this.$refs[ref]) {
        this.$refs[ref].closed();
      }
      if (this.reloadList === true) {
        this.reloadList = false;
      }
    },
    afterLeave() {
      this.activeName = "first";
      if (this.$refs.dealProducts) {
        this.$refs.dealProducts.updateProducts = false;
      }
      if (this.$refs.dealCorrespondence) {
        this.$refs.dealCorrespondence.afterLeave();
      }
      if (this.$refs.dealProducts) {
        this.$refs.dealProducts.emptyProducts();
      }
      this.selectedDealForUpdate = {};
      this.emptyDealCorrespondences();
      if (_.isFunction(this.empty)) {
        this.empty();
      }
      this.$store.commit("services/EMPTY_INVENTORY");
    },
    listChanged() {
      this.parent().listChanged();
    },
    downloadFile(deal_id, fileName) {
      let data = { deal_id: deal_id, file: fileName };
      this.downloadDealFile(data)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
    archivatingDeal(deal_id) {
      this.$confirm(
        this.$t("message.do_you_want_to_archivate_task"),
        this.$t("message.warning"),
        {
          confirmButtonText: "Да",
          cancelButtonText: "Нет",
          type: "warning",
        }
      )
        .then(() => {
          this.listChanged();
          this.archivateDeal(deal_id).then(() => {
            this.parent().closeAddModel();
          });
          this.$message({
            type: "success",
            message: this.$t("message.archivating_completed"),
          });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: this.$t("message.archivating_canceled"),
          });
        });
    },

    async submitProducts(close = true) {
      if (this.$refs.dealProducts) {
        await this.$refs.dealProducts.dispatchAll();
      }
      let message = "";
      let success = true;
      let err_weights = this.selectedDealForUpdate.products.filter(
        (el) => parseFloat(el.weight) === 0
      );
      if (
        this.selectedDealForUpdate.cargo_type === "parcel" &&
        this.selectedDealForUpdate.parcel &&
        this.selectedDealForUpdate.parcel.weight > this.maxWeight
      ) {
        success = false;
        message = this.$t("message.parcel_weight_limit", {
          weight: this.maxWeight,
        });
      }
      if (
        this.selectedDealForUpdate.cargo_type === "parcel" &&
        this.selectedDealForUpdate.parcel &&
        parseFloat(this.totalSMSizeSum) > this.maxSize
      ) {
        success = false;
        message = this.$t("message.parcel_size_limit", {
          size: "300",
          measure: this.$t("message.sm"),
        });
      }
      if (
        this.selectedDealForUpdate.cargo_type === "parcel" &&
        this.selectedDealForUpdate.parcel &&
        this.selectedDealForUpdate.parcel.parcel_products.filter(
          (el) => !el.name
        ).length > 0
      ) {
        success = false;
        message = this.$t("message.enter_product_name");
      }
      if (
        this.selectedDealForUpdate.cargo_type !== "parcel" &&
        err_weights.length > 0
      ) {
        success = false;
        message = this.$t("message.weight_cant_be_0");
      }
      if (!success) {
        return this.$notify({
          title: this.$t("message.warning"),
          type: "warning",
          offset: 130,
          message: message,
        });
      } else {
        this.loadingButton = true;
        let data = {
          deal_id: this.selectedDealForUpdate.id,
          cargo_type: this.selectedDealForUpdate.cargo_type,
          parcel: this.selectedDealForUpdate.parcel,
          packages: this.selectedDealForUpdate.packages,
          products: this.selectedDealForUpdate.products,
        };
        this.update(data)
          .then((res) => {
            this.loadingButton = false;
            this.fetchData(this.selectedDealForUpdate.id);
            this.$alert(res);
          })
          .catch((err) => {
            this.loadingButton = false;
            this.$alert(err);
          });
      }
    },

    async submitDealServices(close = true) {
      if (!_.isEmpty(this.formServices)) {
        this.loadingButton = true;
        let data = {};
        data.services = JSON.parse(JSON.stringify(this.formServices));
        data.deal_id = this.selectedDealForUpdate.id;
        this.updateDealServices(data)
          .then((res) => {
            this.fetchData(this.selectedDealForUpdate.id);
            this.loadingButton = false;
            this.$alert(res);
          })
          .catch((err) => {
            this.loadingButton = false;
            this.$alert(err);
          });
      }
    },
  },
};
</script>

<style>
.step-description {
  font-size: 15px;
}
.KP_modal .el-dialog__body {
  width: 100%;
  background: inherit;
}
.KP_modal .el-dialog {
  background: #f8f8f8;
  border-radius: 10px;
}
.tab-right .el-upload {
  margin-top: -20px;
  margin-bottom: 5px;
}
.mir {
  display: flex;
  justify-content: space-between;
  color: #558cf5;
  border-bottom: 1px solid #d1d1d154;
  padding-top: 7px;
  cursor: pointer;
}
.mir:hover {
  background-color: #e3e3e34a;
  border-color: #d1d1d154;
}

.mirdel {
  display: flex;
  justify-content: space-between;
  /* color: #8a1e35; */
  border-bottom: 1px solid #d1d1d154;
  padding-top: 7px;
  cursor: pointer;
}
.blue__text {
  font-size: 12px;
  color: #558cf5;
  cursor: pointer;
}
.mm__tab__color {
  background: #eef2f4 !important;
}
.overall__product__main {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
