import { i18n } from '@/utils/i18n';

export const columns = {
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    quantity : {
        show: true,
        title: i18n.t('message.quantity'),
        sortable: true,
        column: 'quantity'
    },
    measurement_id : {
        show: true,
        title: i18n.t('message.measurement_id'),
        sortable: true,
        column: 'measurement_id'
    },
    price : {
        show: true,
        title: i18n.t('message.price'),
        sortable: true,
        column: 'price'
    },
    currency_id : {
        show: true,
        title: i18n.t('message.currency_id'),
        sortable: true,
        column: 'currency_id'
    },
    currency_rate : {
        show: true,
        title: i18n.t('message.rate'),
        sortable: true,
        column: 'currency_rate'
    },
    profit : {
        show: true,
        title: i18n.t('message.profit'),
        sortable: true,
        column: 'profit'
    },
    weight : {
        show: true,
        title: i18n.t('message.weight'),
        sortable: true,
        column: 'weight'
    },
    item_weight : {
        show: true,
        title: i18n.t('message.item_weight'),
        sortable: true,
        column: 'item_weight'
    },
    item_weight_in_pounds : {
        show: true,
        title: i18n.t('message.item_weight_in_pounds'),
        sortable: true,
        column: 'item_weight_in_pounds'
    },
    width : {
        show: true,
        title: i18n.t('message.width'),
        sortable: true,
        column: 'width'
    },
    height : {
        show: true,
        title: i18n.t('message.height'),
        sortable: true,
        column: 'height'
    },
    length : {
        show: true,
        title: i18n.t('message.length'),
        sortable: true,
        column: 'length'
    },
    measuring_type : {
        show: true,
        title: i18n.t('message.measuring_type'),
        sortable: true,
        column: 'measuring_type'
    },
    comment : {
        show: true,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },
    total : {
        show: true,
        title: i18n.t('message.total'),
        sortable: true,
        column: 'total'
    },
    delete : {
        show: true,
        title: i18n.t('message.delete'),
        sortable: true,
        column: 'delete'
    },
};
