<template >
    <div class="mm_bordered_section">
        <el-row v-if="deal_service" v-for="(deal_service, key) in dealHistoryPayments" :key="'col_'+key">
            <el-col :span="2">
                <span class="font-bold"> № {{ key+1 }}: </span>
            </el-col>
            <el-col v-if="deal_service.payment_id" :span="3">
                {{ $t('message.payment_number') + ' ' + deal_service.payment_id }}
            </el-col>
            <el-col v-if="deal_service.client_id" :span="5">
                {{ deal_service.client_id }}
            </el-col>
            <el-col v-if="deal_service.payment_type_id" :span="4">
                {{ deal_service.payment_type_id }}
            </el-col>
            <el-col v-if="deal_service.filial_id" :span="4">
                {{ deal_service.filial_id }}
            </el-col>
            <el-col v-if="deal_service.bank_account_id" :span="4">
                {{ deal_service.bank_account_id }}
            </el-col>
            <el-col v-if="deal_service.money_amount >= 0 " :span="2">
                {{ parseFloat(deal_service.money_amount) * parseFloat(deal_service.currency_rate) }} $
            </el-col>
        </el-row>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  export default {
    props: {
        dealHistoryPayments: {
            default: Array
        },
    },
    data() {
      return {
      };
    },
    computed: {
        ...mapGetters({
            calculationTypes: 'services/calculationTypes',
			serviceCalculateTypes: 'services/serviceCalculateTypes',
        }),
    },
    methods: {
        ...mapActions({
        }),
        calculateBy(row) {
			let c_symbol = this.serviceCalculateTypes.find(el => el.id == row.calculate_by);
			return c_symbol ? c_symbol.name : '';
		},
        calculationSymbol(row) {
			let c_symbol = this.calculationTypes.find(el => el.id == row.calculation_type);
			return c_symbol && c_symbol.symbol ? c_symbol.symbol : '$';
		},
    },
  };
  
</script>