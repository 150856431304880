<template>
  <div>
    <div class="d-flex">
      <el-select
        :value="selected"
        @input="onInput"
        :multiple="multiple"
        :placeholder="placeholder || $t('message.client')"
        filterable
        clearable
        :size="size"
        class="w-100"
        :disabled="disabled"
        :loading="loadingData"
      >
        <el-option v-for="(item,index) in itemList" :key="'items-' + index" 
          :label="((item.custom_id ? (item.custom_id + ' | ') : '') + item.name)" 
          :value="item.id">
          {{item.custom_id ? (item.custom_id + ' | ') : ''}} {{item.name}} 
          <span v-if="showLimitsByRole" style="opacity: 0.7"> | {{ item.country ? item.country.name : '' }} | {{ item.phone }}</span>
        </el-option>
      </el-select>
      <div class="d-flex ml-3">
        <el-button  v-if="(create === true) && permissions.some(per => per.slug == 'clients.create')" :size="size" type="primary" icon="el-icon-plus" @click="drawerCreate = true" plain circle></el-button>
        <el-button  v-if="selected && address" :size="size" type="primary" icon="el-icon-location-information" @click="drawerClientAddress = true" plain circle></el-button>
        <el-button  v-if="selected && (update === true) && permissions.some(per => per.slug == 'clients.update')" :size="size" type="primary" icon="el-icon-edit" @click="updateClient()" plain circle></el-button>
      </div>
    </div>
    <!-- Create -->
    <div class="app-modal app-modal__full modal-color-bg">
      <el-drawer
          :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
          v-if="create === true" 
          :with-header="false"
          :visible.sync="drawerCreate"
          ref="drawerCreate"
          size="70%"
          class="bg-se"
          :append-to-body="true"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
      >
          <div>
              <crm-create
                @newInventoryItem="newInventoryItem"
                :title="placeholder || $t('message.client')"
                ref="drawerCreateChild"
                drawer="drawerCreate"
              >
              </crm-create>
          </div>
      </el-drawer>

      <el-drawer
          :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
          v-if="update === true" 
          :with-header="false"
          :visible.sync="drawerUpdate"
          ref="drawerUpdate"
          class="bg-se"
          size="70%"
          :append-to-body="true"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
      >
          <div>
              <crm-update
                :selectedItem="selectedItem"
                :title="placeholder || $t('message.client')"
                @newInventoryItem="newInventoryItem"
                ref="drawerUpdateChild"
                drawer="drawerUpdate"
              >
              </crm-update>
          </div>
      </el-drawer>
      
      <el-drawer
          :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
          v-if="update === true" 
          :with-header="false"
          :visible.sync="drawerClientAddress"
          ref="drawerClientAddress"
          size="70%"
          class="bg-se"
          :append-to-body="true"
          @opened="drawerOpened('drawerClientAddressChild')"
          @closed="drawerClosed('drawerClientAddressChild')"
      >
          <div>
              <client-address
                :client_id="this.selected"
                :deal_id="this.deal_id"
                :type="'deal'"
                v-model="deal_address"
                ref="drawerClientAddressChild"
                drawer="drawerClientAddress"
              >
              </client-address>
          </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
  import CrmCreate from "@/views/clients/components/crm-create";
  import CrmUpdate from "@/views/clients/components/crm-update";
  import clientAddress from "@/views/clients/components/clientAddress/clientAddress";
  import {mapGetters, mapActions} from "vuex";
  import select_create_drawer from "@/utils/mixins/select_create_drawer";
  import _ from 'lodash';
  import { debounce } from 'lodash';
  import axios from 'axios';

  let cancelToken;

  export default {
    mixins: [select_create_drawer],
    components: {
      CrmCreate,
      CrmUpdate,
      clientAddress
    },
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      disabled:{
        default: false
      },
      create:{
        default: false
      },
      update:{
        default: false
      },
      address:{
        default: false
      },
      deal_id:{
        default: null
      },
      role_slug:{
      },
      query:{
        default: Object
      },
      multiple:{
        default: false
      },
    },
    watch:{
      id: {
        handler: function(newVal, oldVal) {
          this.selected = newVal;
        },
        immediate: true,
        deep: true
      },
      deal_address: {
        handler: function(el) {
          if(el && el.id){
            this.$emit('updateDealAddressID', el);
          }
        },
        immediate: true,
        deep: true
      },
      role_slug: {
        handler: function(e) {
          if(e == 'cabinet'){
            this.loadingData = true;
            this.updateDebounce({except_clientType_id: 1});
          }else if(e && e != 'cabinet'){
            this.loadingData = true;
            this.updateDebounce(this.query);
          }
        },
        immediate: true,
        deep: true
      },
      query: {
        handler: function(oldVal, newVal) {
          if(JSON.stringify(oldVal) != JSON.stringify(newVal)){
            this.updateDebounce(this.query);
          }
        },
        immediate: true,
        deep: true
      },
    },
    data() {
      return {
        selected: null,
        drawerClientAddress: false,
        itemList: [],
        deal_address: null,
        loadingData: false,
        currentRequest: null,
        searchQuery: '',
        clients: [],
      }
    },
    computed: {
      ...mapGetters({
        inventoryItems: 'clients/searchInventory',
        all_inventory: 'clients/inventory',
        permissions: "auth/permissions", 
        selectedClient: "clients/model",
        role: "auth/role", 
        mode: "MODE",
      }),
      showLimitsByRole(){
        if(this.role.slug && this.role.slug.includes('warehouse')){
          return false;
        }
        return true; 
      }
    },
    methods: {
      ...mapActions({
        show: "clients/show",
        updateInventory: 'clients/inventory',
        inventorySearch:  'clients/inventorySearch',
      }),
      searchClientsWithLoad: debounce(async function(val) {
        if (cancelToken) {
          cancelToken.cancel('Previous request cancelled');
        }
        
        cancelToken = axios.CancelToken.source();

        try {
          this.loadingData = true;
          const res = await this.inventorySearch({
            search: val,
            ...this.query,
            cancelToken: cancelToken.token
          });

          console.log('Search result:', res);
          this.itemList = Array.isArray(res.data?.result?.data?.clients) 
            ? [...res.data.result.data.clients] 
            : [];
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log('Request cancelled:', error.message);
          } else {
            console.error('Search error:', error);
          }
        } finally {
          this.loadingData = false;
        }
      }, 300),

      onInput: debounce(async function(val) {
        if (!val) return;
        await this.searchClientsWithLoad(val);
      }, 300),



      updateClient(){
        if(this.selectedItem && this.selectedItem.id && this.selected){
          this.drawerUpdate = true;
        }else if(this.selected){
          this.show(this.selected)
            .then((res) => {
              setTimeout(() => {
                this.selectedItem = JSON.parse(JSON.stringify(this.selectedClient));
              }, 100);
              this.drawerUpdate = true;
            })
            .catch(err => {
              this.$notify({
                  title: this.$t('message.warning'),
                  type: "warning",
                  offset: 130,
                  message: this.$t('message.client_not_found')
              });
            })
        }

      }, 
      async searchClients(query) {
          if (cancelToken) {
            cancelToken.cancel('Previous request cancelled');
          }
          cancelToken = axios.CancelToken.source();

          try {
            const res = await axios.get(`/clients/inventory?q=${query}`, {
              cancelToken: cancelToken.token,
            });
            this.clients = res.data?.result?.data?.clients || [];
          } catch (error) {
            if (axios.isCancel(error)) {
              console.log('Request cancelled:', error.message);
            } else {
              console.error('Search error:', error);
            }
          }
        },

        updateDebounce: _.debounce(function(query) {
          this.updateItemsList(query);
        }, 300),

        // Debounce bilan bog‘lash
        onInput: debounce(function (query) {
          this.searchClients(query);
        }, 300),
  


      dispatch(e) {
        if(this.itemList && this.itemList.length > 0 && e && !Array.isArray(e)){
          let client = this.itemList.find(el => el.id === e);
          if(client){
            this.selectedItem = JSON.parse(JSON.stringify(client));  
            this.$emit('updateClientCridentials', client);
            this.$emit('getClient', client);
          }else{
            this.show(e)
              .then((res) => {
                this.selectedItem = JSON.parse(JSON.stringify(this.selectedClient));  
                this.$emit('updateClientCridentials', client);
                this.$emit('getClient', client);

              })
              .catch(err => {
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.client_not_found')
                });
              })
          }
          
        }else{
          this.selectedItem = {};
        }
        this.selected = e;
        this.$emit('input', e);
        
      },
      newInventoryItem(newItem){
        this.loadingData = true;
        this.inventorySearch(this.query).then(res => {
            if(this.inventoryItems && this.inventoryItems.length > 0){
              this.itemList = JSON.parse(JSON.stringify(this.inventoryItems));
              setTimeout(() => {
                this.dispatch(newItem.id);
              }, 300);
            }
            this.loadingData = false;
        }).catch(err => {
            this.loadingData = false;
        });
      },
      updateItemsList(query){
        this.inventorySearch(query)
          .then(res => {
            this.itemList = JSON.parse(JSON.stringify(this.inventoryItems));
            this.loadingData = false;
          });
      }
    },
  }

</script>
