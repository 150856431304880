import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    container_type_id : {
        show: true,
        title: i18n.t('message.containerType'),
        sortable: true,
        column: 'container_type_id'
    },
    barcode : {
        show: true,
        title: i18n.t('message.barcode'),
        sortable: true,
        column: 'barcode'
    },

    deal_id : {
        show: true,
        title: i18n.t('message.deal'),
        sortable: true,
        column: 'deal_id'
    },

    total_weight : {
        show: true,
        title: i18n.t('message.weight_delivery'),
        sortable: true,
        column: 'total_weight'
    },

    products : {
        show: true,
        title: i18n.t('message.products'),
        sortable: true,
        column: 'products'
    },
    client : {
        show: true,
        title: i18n.t('message.client'),
        sortable: true,
        column: 'client'
    },
    sender : {
        show: true,
        title: i18n.t('message.sender'),
        sortable: true,
        column: 'sender'
    },
    reciever : {
        show: true,
        title: i18n.t('message.reciever'),
        sortable: true,
        column: 'reciever'
    },
    partner : {
        show: true,
        title: i18n.t('message.partner'),
        sortable: true,
        column: 'partner'
    },
    current_location : {
        show: true,
        title: i18n.t('message.current_location'),
        sortable: true,
        column: 'current_location'
    },
    from_filial_id : {
        show: true,
        title: i18n.t('message.from_filial'),
        sortable: true,
        column: 'from_filial_id'
    },
    to_filial_id : {
        show: true,
        title: i18n.t('message.to_filial'),
        sortable: true,
        column: 'to_filial_id'
    },
    reciever_address : {
        show: false,
        title: i18n.t('message.reciever_address'),
        sortable: true,
        column: 'reciever_address'
    },
    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
