export const filter = {
    id: "",
    name: "",
    code: null,
    price: "",
    currency_id: null,
    measurement_id: null,
    color_id: null,
    is_active: null,
    packages: [],
    categories: [],
    created_at: "",
    updated_at: ""
};
