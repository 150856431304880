import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],
    deal_types: [
      { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.dealType')}), trigger: 'change' },
    ],
    calculation_type: [
		  { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.calculation_type')}), trigger: 'change' },
    ],
    service_sum: [
		  { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.price')}), trigger: 'change' },
    ],
    currency_id: [
		  { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.currency')}), trigger: 'change' },
    ],
    calculate_by: [
		  { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.calculate_by')}), trigger: 'change' },
    ],
};
