export const getters = {
    list: state => state.list,
    cargoTypes: state => state.cargoTypes,
    archivedDeals: state => state.archivedDeals,
    deletedDeals: state => state.deletedDeals,
    dealTasks: state => state.dealTasks,
    inventory: state => state.inventory,
    dealStages: state => state.dealStages,
    deletedDealsForStage: state => state.deletedDealsForStage,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort ,
    newForm: state => state.form,
    dealsForCalendar: state => state.dealsForCalendar,
    phone_number_deals: state => state.phone_number_deals,
    notificationTypes: state => state.notificationTypes,
    filterOptions: state => state.filterOptions,
    unpaidDealsList: state => state.unpaidDealsList,
    paidMoneyList: state => state.paidMoneyList,
    total: state => state.total,
    total_paid: state => state.total_paid,
    
    form: state => {
        return {
            id:                     state.model.id,
            user_id:                state.model.user ? state.model.user.id : '',
            products:               state.model.products,
            packages:               state.model.packages,
			services:               state.model.services,
            user:                   state.model.user,
            from_filial_id:         state.model.from_filial_id ? state.model.from_filial.id : '',
            to_filial_id:           state.model.to_filial ? state.model.to_filial.id : '',
            direction_tariff_id:    state.model.directionTariff ? state.model.directionTariff.id : '',
            client_id:              state.model.client_id,
            phone:                  state.model.phone, 
            phone_country_code:     state.model.phone_country_code, 
            email:                  state.model.email,    
            status:                 state.model.status,
            notification_type:      state.model.notification_type,
            shipping_service:       state.model.shipping_service,
            shipping_address:       state.model.shipping_address,
            deal_payments:          state.model.deal_payments,
            begin_date:             state.model.begin_date, 
            end_date:               state.model.end_date,
            date:                   state.model.date,
            finish_date:            state.model.finish_date,
            spectators:             state.model.spectators,
            comment:                state.model.comment, 
            files:                  state.model.files,
            
            deal_type_id:                   state.model.deal_type_id, 
            to_client_id:                   state.model.to_client_id, 
            to_client_phone:                state.model.to_client_phone, 
            to_client_phone_country_code:   state.model.to_client_phone_country_code, 
            to_client_email:                state.model.to_client_email, 
            partner_client_id:              state.model.partner_client_id,
            partner_phone:                  state.model.partner_phone,
            partner_phone_country_code:     state.model.partner_phone_country_code, 
            partner_email:                  state.model.partner_email, 
            payer_client_id:                state.model.payer_client_id,
            country:                        state.model.country,
            to_country:                     state.model.to_country,
            partner_country:                state.model.partner_country,
            custom_id:                      state.model.custom_id,
            to_custom_id:                   state.model.to_custom_id,
            partner_custom_id:              state.model.partner_custom_id,
            parcel:                         state.model.parcel,
            cargo_type:                     state.model.cargo_type,
            client_deal_address_id:         state.model.client_deal_address_id,
            client_deal_address:            state.model.client_deal_address,
            to_client_deal_address_id:      state.model.to_client_deal_address_id,
            to_client_deal_address:         state.model.to_client_deal_address,
            delivery_price:                 state.model.delivery_price,
            minimum_weight:                 state.model.minimum_weight,
        }
    }
}