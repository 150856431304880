export const filter = {
    id : '',  
    client_id: '', 
    email: '', 
    track_code: '', 
    send_date: '', 
    user_id: '',
    deal_id: '',
    created_at: '',
    updated_at: ''  
  };
  