import router from '../router/index'
import { getToken } from './auth' // get token from cookie
import getPageTitle from './get-page-title'
import store from "../store/index";
import { Notification } from 'element-ui'

router.beforeEach(async (to, from, next) => {
    const hasToken = store.getters.token || getToken();

    if (hasToken) {
        if (to.path === '/login') {
            next({ path: '/' });
        } else {
            const hasRole = store.getters.role;
            if (hasRole) {
                next();
            } else {
                try {
                    await store.dispatch('auth/getAuth');
                    next(); // ✅ Faqat bitta next()
                } catch (error) {
                    await store.dispatch('auth/resetToken');
                    Notification.error(error || 'Has Error');
                    next('/login?redirect=' + to.path);
                }
            }
        }
    } else {
        if (to.path === '/login') {
            next();
        } else {
            next('/login?redirect=' + to.path);
        }
    }
});


router.afterEach(() => {
    // finish progress bar
});
