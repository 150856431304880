export const model = {
    id: null,
    client_id: null, 
    email: '', 
    track_code: '', 
    send_date: '', 
    user_id: null,
    deal_id: null,
    comment: '',
    created_at: '',
    updated_at: '',
  };
  