import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/dealService',
        method: 'get',
        params
    })
}

export function getProcesses(params) {
    return request({
        url: '/getProcesses',
        method: 'get',
        params
    })
}

export function updateServiceProcess(params) {
    return request({
        url: `/updateServiceProcess/${params.id}`,
        method: 'put',
        params
    })
}


export function showDealServices(params) {
    return request({
        url: `/showDealServices/${params.deal_id}`,
        method: 'get',
        params
    })
}
