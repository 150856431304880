import {
    index, show, store, update, showLast, inventory, changeDealStage, deleteSpectator, dealStages, downloadDealFile, deleteFile, getNotificationTypes,
    dealTasks, archivateDeal, restoreDeal, archivedDeals, deletedDeals, changeIndex, destroy, deleteDeal, getDealsForCalendar, phoneNumberDeals, 
    getFilterOptions, getUnpaidDeals, dealPaidMoney, getDealIdByTrackingCode, updateDealsAddress, downloadDealInfo, batchDeleteDeal,
    cargoTypes 
} from '@/api/deals/deals';

export const actions = {
    
    index({commit}, params = {}){
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit('SET_LIST', res.data.result.data.deals)
                commit('SET_TOTALS', res.data.result)
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })  
        })
    },

    cargoTypes({commit}){
        return new Promise((resolve, reject) => {
            cargoTypes().then(res => {
                commit('SET_CARGO_TYPES', res.data.result.data)
                resolve(res)
            }).catch(err => {
                reject(err)
            })  
        })
    },

    downloadDealInfo({ commit }, deal_id) {
        return new Promise((resolve, reject) => {
            downloadDealInfo(deal_id).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err)
            })
        })
    },

    archivedDeals({commit}, params = {}){
        return new Promise((resolve, reject) => {
            archivedDeals(params).then(res => {
                commit('SET_ARCHIVED_DEALS_LIST', res.data.result.data.deals)
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })  
        })
    },

    phoneNumberDeals({commit}, number){
        return new Promise((resolve, reject) => {
            phoneNumberDeals(number).then(res => {
                commit("PHONE_NUMBER_DEALS", res.data.result.data.deals);
                resolve(res)
            }).catch(err => {
                reject(err)
            })  
        })
    },

    deletedDeals({commit}, params = {}){
        return new Promise((resolve, reject) => {
            deletedDeals(params).then(res => {
                commit('SET_DELETED_DEALS_LIST', res.data.result.data.deals)
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })  
        })
    },
	 
    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.deals);
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },

	 getDealIdByTrackingCode({ commit }, params = {}) {
		return new Promise((resolve, reject) => {
			getDealIdByTrackingCode(params).then(res => {
				  resolve(res.data.result.data)
			 }).catch(err => {
				  reject(err)
			 })
		})
  },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    changeDealStage({ commit }, data) {
        return new Promise((resolve, reject) => {
            changeDealStage(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteSpectator({ commit }, data) {
        const { deal_id, user_id } = data;
        return new Promise((resolve, reject) => {
            deleteSpectator(deal_id, user_id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    downloadDealFile({ commit }, data){
        const { deal_id, file } = data;
        return new Promise((resolve, reject) => {
            downloadDealFile(deal_id, file).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    deleteFile({ commit }, data){
        const { deal_id, file } = data;
        return new Promise((resolve, reject) => {
            deleteFile(deal_id, file).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id).then(res => {
                commit("SET_MODEL", res.data.result.data.deal);
                commit("SET_FORM", res.data.result.data.deal);
                resolve(res.data.result.data)
            }).catch(err => {
                reject(err)
            })
        })
    },

    dealPaidMoney({ commit }, id) {
        return new Promise((resolve, reject) => {
            dealPaidMoney(id).then(res => {
                commit("SET_PAID_MONEY_LIST", res.data.result.data);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    dealTasks({ commit }, id) {
        return new Promise((resolve, reject) => {
            dealTasks(id).then(res => {
                commit("SET_DEAL_TASKS", res.data.result.data.dealTasks);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    getDealsForCalendar({commit}, params) {
        return new Promise((resolve, reject) => {
            getDealsForCalendar(params).then(res => {
                commit('GET_CALENDAR_DEALS', res.data.result.data.deals)
                resolve(res)
            }).catch(err => {
                reject(err)
            })

        })
    },

    archivateDeal({ commit }, id) {
        return new Promise((resolve, reject) => {
            archivateDeal(id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    restoreDeal({ commit }, id) {
        return new Promise((resolve, reject) => {
            restoreDeal(id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    showLast({ commit }) {
        return new Promise((resolve, reject) => {
            showLast().then(res => {
                commit("SET_FORM", res.data.result.data.deal);
                resolve(res.data.result.data);
            }).catch(err => {
                reject(err)
            })
        })
    },

    dealStages({ commit }, filial_id) {
        return new Promise((resolve, reject) => {
            dealStages(filial_id).then(res => {
                commit("SET_DEAL_STAGES", res.data.result.data);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    getNotificationTypes({ commit }) {
        return new Promise((resolve, reject) => {
            getNotificationTypes().then(res => {
                commit("SET_NOTIFICATION_TYPES", res.data.result.data.notificationTypes);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    getUnpaidDeals({commit}, params = {}){
        return new Promise((resolve, reject) => {
            getUnpaidDeals(params).then(res => {
                commit('SET_UNPAID_DEALS_LIST', res.data.result.data.deals)
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })  
        })
    },

    getFilterOptions({ commit }, params) {
        return new Promise((resolve, reject) => {
            getFilterOptions(params).then(res => {
                commit("SET_FILTER_OPTIONS", res.data.result.data.filterOptions);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    changeIndex({ commit }, data) {
        return new Promise((resolve, reject) => {
            changeIndex(data).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })
        })
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    deleteDeal({ commit }, data) {
        const { deal_id, reason_id } = data;
        return new Promise((resolve, reject) => {
            deleteDeal(deal_id, reason_id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    batchDeleteDeal({ commit }, data) {
        return new Promise((resolve, reject) => {
            batchDeleteDeal(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    updateDealsAddress({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateDealsAddress(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    emptyDealTask({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_DEAL_TASKS");
            resolve()
        })
    },
    
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    toggleColumns({ commit }, obj) {
        commit('TOGGLE_COLUMNS', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    

}

