import request from '@/utils/request';

export function show() {
    return request({
        url: '/systemSettings/show',
        method: 'get',
    })
}
export function update(data) {
    return request({
        url: '/systemSettings/update',
        method: 'put',
        data
    })
}