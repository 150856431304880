export const model = {
  id : null,  
  name: '', 
  code: null, 
  price: 0, 
  currency_id: null, 
  weight: 0,
  calculation_type: null,
  measurement_id: null, 
  product_model_id: null, 
  color_id: null, 
  notification_id: null, 
  is_active: false,   
  is_default: true,   
  categories: [], 
  packages: [], 
  images: [], 
  names: {}, 
  rules: [],
  created_at: '',
  updated_at: ''  
  };
  