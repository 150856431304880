<template>
  <div>
    <div class="d-flex">
      <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.item')" filterable remote :remote-method="searchWithLoad"
        clearable :size="size" :disabled="disabled" class="w-100" :loading="loadingData">
        <el-option v-for="(item,index) in itemList" :key="'items-' + index" 
          :label="((item.custom_id ? item.custom_id + ' | ' : '') + item.name)"   
          :value="item.id">
          {{item.name}} <span style="opacity: 0.7"> {{ item.country ? ' | ' + item.country : '' }} {{ item.city ? ' | ' + item.city : '' }}</span>
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from "vuex";
  import _ from 'lodash';

  export default {
    props: {
      size: {
        default: 'large'
      },
      placeholder: {
        default: null,
      },
      disabled: {
          default: false,
      },
      id:{
        default: null
      },
      select_only: {
        default: true,
      },
      show_all:{
        default: 0
      },
    },
    watch:{
      id: {
        handler: function(e) {
          this.selected = e
          this.findAndEmit(e);
        },
        immediate: true,
        deep: true
      },
      select_only: {
        handler: function(e) {
          if(this.select_only){
            let default_item = this.itemList.find(el => el.default == true);
            default_item ? this.dispatch(default_item.id) : '';
          }
        },
        immediate: true,
        deep: true
      },
    },
    data() {
      return {
        selected: null,
        itemList: [],
        loadingData: false,
      }
    },
    mounted() {
      if (this.itemList && this.itemList.length === 0)
        this.inventorySearch({show_all: this.show_all})
          .then(res => {
            this.itemList = JSON.parse(JSON.stringify(this.inventoryItems));
            if(this.select_only){
                let default_item = this.itemList.find(el => el.default == true);
                default_item ? this.dispatch(default_item.id) : '';
            }
          })
    },
    computed: {
      ...mapGetters({
        inventoryItems: "filials/userFilials",
        back_search: "filials/user_back_search",
      }),
    },
    methods: {
      ...mapActions({
        inventorySearch: "filials/userFilials",
      }),
      searchWithLoad(val){
        this.loadingData = true;
        this.searchFilials(val);
      },
      searchFilials: _.debounce(
        function(val) {
          if(val){
            this.updateItemsList({search: val});
          }else{
            this.updateItemsList({})
          }
        }, 500),  

      dispatch(e) {
        this.selected = e;
        this.$emit('input', e);
        this.findAndEmit(e);
      },
      findAndEmit(id) {
        if(id){
          let item = this.itemList.find(el => el.id == id);
          this.$emit('getSelectedItem', item);
        }
      },
      updateItemsList(query){
        this.inventorySearch({...query, show_all: this.show_all})
          .then(res => {
            this.itemList = JSON.parse(JSON.stringify(this.inventoryItems));
            this.loadingData = false;
          }).catch(err => {
            this.loadingData = false;
          });
      }
    },
  }

</script>
<style scoped>
  .mm__min__width {
    min-width: 170px;
  }
</style>