<template >
    <!-- PACKAGES -->
    <div class="mm_bordered_section">
        <div v-for="(deal_package, key) in dealHistoryPackages" :key="'col_'+key">
            <el-row v-if="deal_package">
                <el-col :span="2">
                    <span class="font-bold">{{ $t('message.package') }} № {{ key+1 }}: </span>
                </el-col>
                <el-col :span="7">
                    {{ deal_package.container_type_id }} 
                    <span v-if="deal_package.status">
                        (<span class="font-bold">{{ deal_package.status == 'created' ? $t('message.new') : $t('message.old') }}</span>)
                    </span>
                </el-col>
                <el-col :span="6">
                    {{ deal_package.barcode }}
                </el-col>
                <el-col v-if="deal_package.total_weight" :span="6">
                    {{ deal_package.total_weight }} {{ $t('message.kg') }}
                </el-col>
                <el-col v-if="!deal_package.total_weight" :span="6">
                    {{ calculateRowTotalWeight(deal_package) }} {{ $t('message.kg') }}
                </el-col>
                <el-col v-if="deal_package.container" :span="3">
                    {{ $t('message.container') }}: {{ deal_package.container }}
                </el-col>
                <el-col v-if="deal_package.client_id" :span="3">
                    {{ $t('message.client') }}: {{ deal_package.client_id }}
                </el-col>
                <el-col v-if="deal_package.filial_id" :span="3">
                    {{ $t('message.filial') }}: {{ deal_package.filial_id }}
                </el-col>
                <el-col v-if="deal_package.from_filial_id" :span="3">
                    {{ $t('message.from_filial') }}: {{ deal_package.from_filial_id }}
                </el-col>
                <el-col v-if="deal_package.to_filial_id" :span="3">
                    {{ $t('message.to_filial') }}: {{ deal_package.to_filial_id }}
                </el-col>
                <el-col v-if="deal_package.transfer" :span="3">
                    {{ $t('message.party') }}: {{ deal_package.transfer }}
                </el-col>
            </el-row>
            <div v-if="deal_package.deal_products && deal_package.deal_products.length > 0">
                <span class="font-bold"> {{ $t('message.products') }}: </span>
                <div class="ml-5">
                    <dealHistoryForProducts :dealHistoryProducts="deal_package.deal_products"></dealHistoryForProducts>
                </div>
            </div>
            <br>
        </div>
    </div>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import dealHistoryForProducts from "./dealHistoryForProducts";

export default {
    props: {
        dealHistoryPackages: {
            default: Array
        },
    },
    components: {
        dealHistoryForProducts,
    },
    data() {
      return {
      };
    },
    computed: {
        ...mapGetters({
        }),
    },
    methods: {
        ...mapActions({
        }),
        calculateRowTotalWeight(deal_package) {
            let weight = 0;
            if(deal_package.deal_products && deal_package.deal_products.length > 0){
                deal_package.deal_products.forEach(prod => {
                    weight += parseFloat(prod.weight || 0);
                });
            }
            return weight;
        }
    },
};
</script>