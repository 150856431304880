<template>
    <div class="country-phone-selector">
        <el-form-item
            class=""
            :prop="form_prop"
            :label="$t('message.phone_number')"
        >
            <VuePhoneNumberInput @update="getNumber" required :no-example="true" :default-country-code="countryCode" v-model="value" />
        </el-form-item>    
    </div>
</template>
<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import parsePhoneNumber from 'libphonenumber-js'

export default {
    components: {
        VuePhoneNumberInput,
    },
    props: {
        form_prop:{
            default: 'phone_number',
        },
        inputValue:{
            default: '',
        },
        country_code:{
            default: 'UZ',
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            value: null,
            countryCode: "UZ",
        };
    },
    computed:{
      
    },
    watch:{
      inputValue:{
        handler: function() {              
            this.value = this.inputValue;
            this.detectCountryCode(this.value);    
        },
        immediate: true,
        deep: true
      },
    },
    methods: {
        getNumber(phone){
            this.$emit("input", phone.formattedNumber);
            this.$emit("getPhoneStatus", {is_valid: phone.isValid, countryCode: phone.countryCode, calling_code: phone.countryCallingCode});
        },
        detectCountryCode(phoneNumber) {
           
            try {
                if(phoneNumber ){
                    const parsedNumber = parsePhoneNumber(phoneNumber);
                    if (parsedNumber) {
                        this.countryCode = parsedNumber.country || "UZ";
                    }
                }
            } catch (error) {
                console.error("Error detecting country code:", error);
            }
        },
    },
};
</script>
<style>
.country-phone-selector .country-selector__label {
  top: -10px !important;
}
.country-phone-selector .input-tel__label {
  top: -10px !important;
}
</style>