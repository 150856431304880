import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],
    url: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.url')}), trigger: 'change' },
    ],
    register_rule_id: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.registerRule')}), trigger: 'change' },
    ],
    countries: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.countries')}), trigger: 'change' },
    ],
    filials: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.filials')}), trigger: 'change' },
    ],
    tariffs: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.directionTariffs')}), trigger: 'change' },
    ],
    dark_plugin: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.dark_plugin')}), trigger: 'change' },
    ],
    light_plugin: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.light_plugin')}), trigger: 'change' },
    ],
};
