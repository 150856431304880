<template >
    <div>
      <div class="app-modal__box">
        <div class="app-modal__in">
          <div class="app-modal__header d-flex f-between p-5 m-title-modal">
            <p class="large--title m-0"> {{ $t("message.edit_m", { m: $t("message.service") }) }}</p>
            <div>
               <crm-store-update-close
                  :permission="$options.name"
                  :button_type="'store'"
                  :loading="loadingButton"
                  @c-submit="submit(true)"
                  @c-close="close()"
              ></crm-store-update-close>
            </div>
          </div>
        </div>
        <!-- app-modal__header end -->
        <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
          <div class="timeline-items__right rounded-sm w-100 p-4">
            <div>
                <span class="font-bold">{{ selectedItem.name ? selectedItem.name : selectedItem.service ? selectedItem.service.name : '' }} - </span>
                <span> {{ $t('message.there_is_only_saved_products_will_be_added') }} </span>
            </div>
            <div>
                <template>
                    <el-table
                        :data="serviceProducts"
                        show-summary
                        :summary-method="getSummariesForService"
                        style="width: 100%"
                    >
                        <el-table-column
                            type="index"
                            width="50">
                        </el-table-column>
                        <el-table-column
                            prop="product_name"
                            :label="$t('message.name')">
                        </el-table-column>
                        <el-table-column
                            prop="comment"
                            :label="$t('message.comment')">
                        </el-table-column>
                        <el-table-column
                            prop="barcode"
                            :label="$t('message.barcode')"
                            width="180">
                            <template slot-scope="item">
                                <i v-if="item.row.packed" class="el-icon-box mm_package_icon_color"></i> {{ item.row.barcode }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="value"
                            :label="$t('message.price')"
                            width="130">
                            <template slot-scope="item">
                                <el-input 
                                    v-model="item.row.value" 
                                    :placeholder="$t('message.price')" 
                                    size="medium" 
                                >
                                    <template slot="append">{{ item.row.row_price_sybol }}</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="currency_rate"
                            :label="$t('message.rate')">
                        </el-table-column>
                        <el-table-column
                            prop="currency"
                            :label="$t('message.currency')">
                            <template slot-scope="item">
                                {{ item.row.currency ? item.row.currency.symbol : '' }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="row_total"
                            :label="$t('message.total')">
                            <template slot-scope="item">
                                {{ item.row.row_total }} $
                                <span v-if="item.row.hasOwnProperty('pc_row_total') && item.row.pc_row_total >= 0">
                                    / {{ item.row.pc_row_total }} $
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </div>
          </div>
        </div>
        <!-- end app-modal__body -->
      </div>
    </div>
</template>
  
<script>
    import { mapGetters, mapActions } from "vuex";
    import drawer from "@/utils/mixins/drawer";
    import { formatMoney } from "@/filters/index";
    export default {
        mixins: [drawer],
        props:{
            selectedItem:{
                type: Object
            },
        },
        data() {
            return {
                form: {},
                loadingButton: false,
                loadingData: false,
                serviceProducts: [],
            };
        },
        created() {},
        computed: {
            ...mapGetters({
                model: "services/showDealProductServices",
                calculationTypes: 'services/calculationTypes',
                columns: "services/columns",
            }),
        },
        methods: {
            ...mapActions({
                update: "services/update",
                show: "services/showDealProductServices",
            }),
            afterOpen() {        
                this.fetchData()
            },
            fetchData() {
                if(this.selectedItem.deal_product_services){
                    this.serviceProducts = JSON.parse(JSON.stringify(this.selectedItem.deal_product_services));
                    this.serviceProducts.forEach(element => {
                        element.row_price_sybol = this.calculationSymbol(element);
                    });
                }
            },
            calculationSymbol(row) {
                let c_symbol = this.calculationTypes.find(el => el.id == row.calculation_type);
                return c_symbol ? c_symbol.symbol : '';
            },
            submit(close = true) {
                this.$emit('getDealProductServices', this.serviceProducts);
                this.emptyService();
            },
            emptyService() {
                this.serviceProducts = [];
            },
            afterClose() {
                this.emptyService();
            },
            getSummariesForService(param) {
                const { columns, data } = param;
                const sums = [];
                let total_money = 0;
                let pc_total_money = 0;
                if (!_.isEmpty(data)) {
                    data.forEach(item => {
                        total_money += parseFloat(item.row_total || 0);
                        pc_total_money += parseFloat(item.pc_row_total || 0);
                    });
                }
                columns.forEach((column, index) => {
                    if (index === 1) {
                        sums[index] = this.$t('message.total_amount');
                        return;
                    }
                    if (column.label == this.$t('message.total')) {
                        sums[index] = formatMoney(total_money, 2) + ' / ' + formatMoney(pc_total_money, 2);
                        return;
                    }
                });

                return sums;
            },
        },
    };
</script>
<style>
.mm_package_icon_color {
    color: orange;
}
</style>