<template>
    <div class="mm_custom_input_padding_with_append">
        <el-input
            :value="value"
            @input="dispatch"
            :type="type"
            :min="min"
            :size="size"
            :disabled="disabled"
            :class="className"
            :placeholder="placeholder"
            v-show="v_show"
        >
            <template slot="append"> {{ measurement_type ? $t('message.' + measurement_type ) : '' }} </template>
        </el-input>
    </div>
</template>
<script>
export default {
    name: "user_status",
    props: {
        size: {
            default: "small",
        },
        placeholder: {
            default: '',
        },
        type: {
            default: "text",
        },
        className: {
            default: "w-100",
        },
        icon: {
            default: null,
        },
        inputValue:{
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        measurement_type: {
            type: String,
            default: ''
        },
        min: {
            type: Number,
            default: 0
        },
        v_show: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            value: null,
        };
    },
    computed:{
    },
    watch:{
      inputValue:{
        handler: function() {                     
            this.value = this.inputValue;
        },
        immediate: true
      },
    },
    methods: {
        dispatch(e) {
            this.$emit("input", e);
            this.value = e;
        },
    },
};
</script>
<style>
/* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .mm_custom_input_padding_with_append .el-input__inner {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

 .mm_custom_input_padding_with_append .el-input-group__append {
    padding: 0px !important;
    width: 35px !important;
    text-align: center;
  }
</style>