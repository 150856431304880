<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{ $t("message.edit_m", {m: $t("message.product"),})}}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <div class="app-modal__body p-5 pb-0">
          <div class="timeline-items__right rounded-sm w-100 p-4">
              <el-row :gutter="20">
                <el-col :span="8" v-for="sys_lang in systemLanguages" :key="'lang'+sys_lang.code">
                  <el-form-item
                    :label="$t('message.'+sys_lang.code)"
                    class="label_mini"
                  >
                    <el-input
                      :placeholder="$t('message.name')"
                      v-model="form.names[sys_lang.code]"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </el-col>

              </el-row>
          </div>
        </div>

        <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
          <div class="timeline-items__right rounded-sm w-100 p-4">
            
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  :label="$t('message.name')"
                  class="label_mini"
                  prop="name"
                >
                  <el-input
                    :placeholder="$t('message.name')"
                    v-model="form.name"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('message.calculation_type')" prop="calculation_type" class="label_mini">
                  <radio-calculation-type
                    v-model="form.calculation_type"
                    :id="form.calculation_type"
                  >
                  </radio-calculation-type>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('message.currency_id')"
                  class="label_mini"
                  prop="currency_id"
                >
                  <select-currency
                    ref="Currency"
                    :size="'medium'"
                    :placeholder="columns.currency_id.title"
                    :id="form.currency_id"
                    v-model="form.currency_id"
                  >
                  </select-currency>
                </el-form-item>
              </el-col>

              
              <el-col :span="12">
                <el-form-item
                  :label="$t('message.is_active')"
                  class="label_mini"
                  prop="is_active"
                >
                <el-switch
                  v-model="form.is_active"
                  :active-text="$t('message.active')"
                  :inactive-text="$t('message.inactive')">
                </el-switch>
                </el-form-item>
              </el-col>

            </el-row>
            
            <el-button type="primary" plain icon="el-icon-money" @click="minimumWeightDrawer = true">{{$t('message.minimum_weight_list')}}</el-button>
            <el-button type="primary" plain icon="el-icon-money" @click="priceListDrawer = true">{{$t('message.price_list')}}</el-button>
          
          </div>
        </div>
      </el-form>
    </div>

    <el-drawer
      :with-header="false"
      :visible.sync="priceListDrawer"
      :wrapperClosable="false"
      class="bg-se"
      :append-to-body="true"
      size="80%"
      ref="priceList"
      @opened="drawerOpened('priceListChild')"
      @closed="drawerClosed('priceListChild')"
    >
      <price-list
        :product="model"
        ref="priceListChild"
        drawer="priceList"
      ></price-list>
    </el-drawer>
    <el-drawer
      :with-header="false"
      :wrapperClosable="false"
      :visible.sync="minimumWeightDrawer"
      class="bg-se"
      :append-to-body="true"
      size="80%"
      ref="minimumWeight"
      @opened="drawerOpened('minimumWeightChild')"
      @closed="drawerClosed('minimumWeightChild')"
    >
      <minimum-weight-list
        :product="model"
        ref="minimumWeightChild"
        drawer="minimumWeight"
      ></minimum-weight-list>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import child_list from "@/utils/mixins/child_list";
import selectCurrency from "@/components/inventory/select-currency";
import selectMeasurement from "@/components/inventory/select-measurement";
import RadioCalculationType from "@/components/radioSelect/radio-calculation-type";
import selectCategories from "@/components/multiSelects/multi-select-categories";
import _ from 'lodash';
import PriceList from "./new-price-list";
import MinimumWeightList from "./minimum_weight_list";

export default {
  name: "products",
  mixins: [form, drawer, show, child_list],
  props: {
    notification_id: {
      default: null
    },
  },
  components: {
    selectCurrency,
    selectMeasurement,
    selectCategories,
    PriceList,
    MinimumWeightList,
    RadioCalculationType
  },
  data() {
    return {
      minimumWeightDrawer: false,
      priceListDrawer: false,
      imageList: [],
      updateImage: [],
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "products/rules",
      model: "products/model",
      columns: "products/columns",
      images_list: "products/images_list",
      categories: "categories/inventory",
      measurements: "measurements/inventory",
      systemLanguages: "systemLanguages",
    }),

    weightLabel(){
      let measur = this.form.measurement_id ? this.measurements.find(el => el.id === this.form.measurement_id) : '';
      return `${this.$t('message.weight_in_kg')} ${this.$t('message.for')} ${measur.name ? measur.name : ''}`
    }
  },
  methods: {
    ...mapActions({
      update: "products/update",
      show: "products/show",
      deleteImage: "products/deleteImage",
      empty: "products/empty",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$set(this.form, 'notification_id', this.notification_id);
          let formData = new FormData();
          for (const key in this.form) {
              if (key != 'is_default' && key !== 'names') {
                  if (this.form[key]) {
                      formData.append(key, this.form[key]);
                  }
                  else {
                      formData.append(key, '');
                  }
              } else if (key == 'is_default') {
                  formData.append(key, this.form[key]);
              } else if (key === 'names') {
                  formData.append(key, JSON.stringify(this.form[key]));
              }
          }

          if (_.size(this.updateImage) > 0) {
              for (const key in this.updateImage) {
                  if (this.updateImage.hasOwnProperty(key)) {
                      const element = this.updateImage[key];
                      formData.append(`images[${key}]`, element);
                  }
              }
          }

          this.loadingButton = true;
          this.update(formData)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (res.status == 200) this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },

    fetchData() {
      if (!this.loadingData) {  
          this.loadingData=true        
          this.show(this.selectedItem.id).then(res => {  
              this.imageList = JSON.parse( JSON.stringify( this.images_list ));
              this.loadingData = false              
          }).catch(err => {
              this.loadingData = false
          });
      }
    },

    updateImageList(file) {
        this.updateImage.push(file.raw);
    },
    handleRemove(file) {
        if (file.id) {
            this.deleteImage({ product_id: this.form.id, image_id: file.id }).then(res => {
                this.$alert(res);
            }).catch(err => {});
        } else {
            for (var i = 0; i < _.size(this.updateImage); i++) {
                if (this.updateImage[i].name === file.name) {
                    this.updateImage.splice(i, 1);
                }
            }

        }
    },

    defaultChange(e){
      this.form.is_default = e;
      if(e){
        this.form.product_model_id = null;
      }
    },
    afterLeave(){
      this.empty();
      this.imageList = []
    }
  },
};
</script>
